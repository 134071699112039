/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import AppDialog from 'components/AppDialog';
import { useTranslation } from 'react-i18next';
import * as dateFns from 'date-fns';
import qs from 'qs';
import AppButton from 'components/AppButton';
import { Field, Formik } from 'formik';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { getTime, startOfYear, subYears } from 'date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import spinnerService from 'services/SpinnerService';
import FecService from 'services/FecService';
import FileSaver from 'file-saver';
import { zonedTimeToUtc } from 'date-fns-tz';
import AppCheckbox from 'components/AppCheckbox';
import { useSelector } from 'react-redux';

const TRANSACTION_TYPES = [
  {
    value: 'ALL',
    label: 'book_keeping.export_history_dialog.type_options.all',
  },
  {
    value: 'VE',
    label: 'book_keeping.export_history_dialog.type_options.ve',
  },
  {
    value: 'ACH',
    label: 'book_keeping.export_history_dialog.type_options.ach',
  },
  {
    value: 'BQ',
    label: 'book_keeping.export_history_dialog.type_options.bq',
  },
  {
    value: 'CA',
    label: 'book_keeping.export_history_dialog.type_options.ca',
  },
];
const ExportHistoryDialog = ({ showDialog, onClose }) => {
  const { t } = useTranslation();
  const [error, setError] = useState();
  const endFiscalYear = useSelector(
    (state) => state.loggedUserCompany.company.endFiscalYear
  );
  function downloadFile(data, filename) {
    if (!data) {
      return null;
    }

    return FileSaver.saveAs(
      new Blob([data], { type: 'application/zip' }),
      filename
    );
  }
  const allTypes = useMemo(
    () => TRANSACTION_TYPES.slice(1).map((item) => item.value),
    []
  );

  const handleSubmit = async (values) => {
    // TODO: submit

    try {
      spinnerService.startSpinner();
      const startDate = dateFns.format(values.fromDate, 'dd/MM/yyyy');

      const endDate = dateFns.format(values.toDate, 'dd/MM/yyyy');

      const type = values.type || allTypes;

      const query = qs
        .stringify({ type, endDate, startDate }, { arrayFormat: 'comma' })
        .toString();

      const res = await FecService.downloadExportedHistory(query);

      const filename = `export_${type}_${dateFns.format(
        dateFns.parse(startDate, 'dd/MM/yyyy', new Date()),
        'yyyyMMdd'
      )}_${dateFns.format(
        dateFns.parse(endDate, 'dd/MM/yyyy', new Date()),
        'yyyyMMdd'
      )}.zip`;
      downloadFile(res.data, filename);
      setError('');
      onClose();
    } catch (error) {
      const errorData = await error.text();
      const { message } = JSON.parse(errorData);
      if (message === 'BOOKS_NOT_FOUND')
        setError(t('book_keeping.export_history_dialog.books_not_found'));
      else setError(t('forms.internal_error'));
    } finally {
      spinnerService.resetSpinner();
    }
  };

  const isAllTypes = (types) =>
    !TRANSACTION_TYPES.slice(1).find((item) => !types.includes(item.value));

  const toggleTransactionType = (currentType, types, setFieldValue) => {
    if (types?.includes(currentType)) {
      setFieldValue(
        'type',
        types.filter((type) => type !== currentType)
      );
    } else {
      setFieldValue('type', [...types, currentType]);
    }
  };

  function getNextFiscalDate(fromDate, endFiscalYear) {
    const [endDay, endMonth] = endFiscalYear.split('/').map(Number);

    let fiscalDate = dateFns.setDate(
      dateFns.setMonth(fromDate, endMonth - 1),
      endDay
    );

    if (dateFns.isAfter(fromDate, fiscalDate)) {
      fiscalDate = dateFns.addYears(fiscalDate, 1);
    }
    return fiscalDate;
  }

  function getPrevFiscalDate(toDate, endFiscalYear) {
    const [endDay, endMonth] = endFiscalYear.split('/').map(Number);

    let fiscalDate = dateFns.setDate(
      dateFns.setMonth(toDate, endMonth - 1),
      endDay
    );

    if (
      dateFns.isBefore(toDate, fiscalDate) ||
      dateFns.isEqual(toDate, fiscalDate)
    ) {
      fiscalDate = subYears(fiscalDate, 1);
    }

    return dateFns.addDays(fiscalDate, 1);
  }

  function isValidFiscalYearRange(fromDate, toDate, endFiscalYear) {
    const [endDay, endMonth] = endFiscalYear.split('/').map(Number);

    let fiscalYearEndDate = dateFns.setDate(
      dateFns.setMonth(fromDate, endMonth - 1),
      endDay
    );

    if (
      dateFns.isAfter(
        dateFns.startOfDay(fromDate),
        dateFns.startOfDay(fiscalYearEndDate)
      )
    ) {
      fiscalYearEndDate = dateFns.addYears(fiscalYearEndDate, 1);
    }

    let fiscalYearStartDate = dateFns.addYears(fiscalYearEndDate, -1);
    fiscalYearStartDate = dateFns.setDate(
      dateFns.setMonth(fiscalYearStartDate, endMonth - 1),
      endDay + 1
    );

    const fromDateIsValid =
      (dateFns.isAfter(
        dateFns.startOfDay(fromDate),
        dateFns.startOfDay(fiscalYearStartDate)
      ) ||
        dateFns.isEqual(
          dateFns.startOfDay(fromDate),
          dateFns.startOfDay(fiscalYearStartDate)
        )) &&
      (dateFns.isBefore(
        dateFns.startOfDay(fromDate),
        dateFns.startOfDay(fiscalYearEndDate)
      ) ||
        dateFns.isEqual(
          dateFns.startOfDay(fromDate),
          dateFns.startOfDay(fiscalYearEndDate)
        ));
    const toDateIsValid =
      dateFns.isAfter(
        dateFns.startOfDay(toDate),
        dateFns.startOfDay(fiscalYearStartDate)
      ) &&
      (dateFns.isBefore(
        dateFns.startOfDay(toDate),
        dateFns.startOfDay(fiscalYearEndDate)
      ) ||
        dateFns.isEqual(
          dateFns.startOfDay(toDate),
          dateFns.startOfDay(fiscalYearEndDate)
        ));

    return fromDateIsValid && toDateIsValid;
  }

  if (showDialog) {
    return (
      <AppDialog
        title={t('book_keeping.export_history_dialog.title')}
        color="secondary"
        iconClose
        closeDialog={() => onClose()}
        dialogStyles={{
          paddingTop: 8,
        }}
      >
        <Formik
          initialValues={{
            fromDate: null,
            toDate: null,
            type: allTypes,
          }}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, handleSubmit, values, handleChange }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <div
                  style={{
                    width: 'max-content',
                    fontSize: '16px',
                    fontWeight: 500,
                    color: '#111111',
                    marginTop: 8,
                    marginBottom: 8,
                  }}
                >
                  {t('book_keeping.export_history_dialog.date_title')} :
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: 16,
                  }}
                >
                  <div style={{ fontSize: 14, marginTop: 10 }}>
                    {t('book_keeping.export_history_dialog.from')}
                  </div>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Field name="fromDate">
                      {({ field }) => (
                        <KeyboardDatePicker
                          clearable
                          value={field.value}
                          onChange={(date) => setFieldValue(field.name, date)}
                          renderInput={(params) => <TextField {...params} />}
                          minDate={
                            values.toDate
                              ? getPrevFiscalDate(values.toDate, endFiscalYear)
                              : undefined
                          }
                          maxDate={values?.toDate || undefined}
                          format={'dd/MM/yyyy'}
                          keyboardInputLabelProps={{ shrink: true }}
                          InputProps={{
                            margin: 'dense',
                          }}
                          inputVariant="outlined"
                          fullWidth
                        />
                      )}
                    </Field>
                    <div style={{ fontSize: 14, marginTop: 10 }}>
                      {t('book_keeping.export_history_dialog.to')}
                    </div>
                    <Field name="toDate">
                      {({ field }) => (
                        <KeyboardDatePicker
                          clearable
                          value={field.value}
                          onChange={(date) => setFieldValue(field.name, date)}
                          renderInput={(params) => <TextField {...params} />}
                          maxDate={
                            values.fromDate
                              ? getNextFiscalDate(
                                  values.fromDate,
                                  endFiscalYear
                                )
                              : undefined
                          }
                          minDate={
                            values.fromDate ? values.fromDate : undefined
                          }
                          format={'dd/MM/yyyy'}
                          keyboardInputLabelProps={{ shrink: true }}
                          InputProps={{
                            margin: 'dense',
                          }}
                          inputVariant="outlined"
                          fullWidth
                        />
                      )}
                    </Field>
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <FormControl
                style={{
                  marginTop: 16,
                  marginBottom: 16,
                }}
              >
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  style={{
                    fontSize: '16px',
                    fontWeight: 500,
                    color: '#111111',
                    marginTop: 8,
                  }}
                >
                  {t('book_keeping.export_history_dialog.types_title')}
                </FormLabel>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 16,
                  }}
                >
                  <AppCheckbox
                    checked={isAllTypes(values.type)}
                    onClick={() => {
                      if (isAllTypes(values.type)) setFieldValue('type', []);
                      else setFieldValue('type', allTypes);
                    }}
                    labelText={t(TRANSACTION_TYPES[0].label)}
                  />
                  {TRANSACTION_TYPES.slice(1).map((transaction) => (
                    <AppCheckbox
                      key={transaction.value}
                      checked={values.type.includes(transaction.value)}
                      onClick={() => {
                        toggleTransactionType(
                          transaction.value,
                          values.type,
                          setFieldValue
                        );
                      }}
                      labelText={t(transaction.label)}
                    />
                  ))}
                </div>
              </FormControl>
              {error && (
                <p
                  style={{
                    color: 'red',
                    marginTop: -20,
                    textAlign: 'right',
                    marginRight: 10,
                  }}
                >
                  {error}
                </p>
              )}
              <div
                style={{
                  display: 'flex',
                  gap: 16,
                  width: '100%',
                  justifyContent: 'right',
                }}
              >
                <AppButton
                  color="transparentGrey"
                  text={t('cancel')}
                  onClick={onClose}
                />
                <AppButton
                  color="secondaryLight"
                  text={t('book_keeping.list.download')}
                  noBorder={true}
                  isDisabled={
                    !values.type?.length ||
                    !values.fromDate ||
                    !values.toDate ||
                    !isValidFiscalYearRange(
                      values.fromDate,
                      values.toDate,
                      endFiscalYear
                    )
                  }
                  type="submit"
                />
              </div>
            </form>
          )}
        </Formik>
      </AppDialog>
    );
  }

  return null;
};

ExportHistoryDialog.propTypes = {
  showDialog: PropTypes.bool,
  onClose: PropTypes.func,
  selected: PropTypes.string,
  t: PropTypes.func,
  importItem: PropTypes.array,
  onDownload: PropTypes.func,
};

export default ExportHistoryDialog;
