import { createExpense } from 'actions/ExpensesActions';
import { globalDispatch } from 'store';

export const handleCreateExpense = async (values) => {
  const { provider } = values;
  const expenses = [values];
  const newValues = {
    ...values,
    provider: !provider ? null : provider,
  };
  const newExpenses = expenses.map((item) => ({
    ...item,
    provider: !item.provider ? null : item.provider,
  }));
  const expense = await globalDispatch(
    createExpense({ values: newValues, expenses: newExpenses })
  );
  return expense;
};

export default {};
