import axios from 'axios';
import { authInstance } from '../../helpers/axiosInterceptor';

const getOcrConfig = async () => {
  const result = await authInstance.get(
    `${process.env.REACT_APP_OCR_URL}/get-ocr-config`
  );
  return result.data;
};

const getOrcDimarcConfig = async () => {
  const result = await authInstance.get(
    `${process.env.REACT_APP_DIMARC_URL}/token`,
    {
      params: {
        client_id: process.env.REACT_APP_DIMARC_CLIENT_ID,
        client_secret: process.env.REACT_APP_DIMARC_CLIENT_SECRET,
      },
    }
  );
  return result.data;
};

const recogniseReceipt = async (file, url, key) => {
  const formData = new FormData();

  formData.append('file', file);

  return axios.post(url, formData, {
    timeout: 30 * 1000, // 30 seconds
    headers: {
      apikey: key,
      'content-type': 'multipart/form-data',
    },
  });
};

const scanORCwithDimarc = async (file, config) => {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('document_type', 'invoice');
  // f8a22df073f07950ca695c1e90a1aeb8
  // d6b2b43f79b8e0590dead7b27e830468
  return axios.post(
    `${process.env.REACT_APP_DIMARC_URL}/services/extraction/bbf`,
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data',
        [config.token_type]: config.access_token,
      },
    }
  );
};

const asyncScanORCwithDimarc = async (file, config, cacheId) => {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('document_type', 'invoice');
  formData.append(
    'callback_url',
    `${process.env.REACT_APP_OCR_URL}/dimarc-webhook?id=${cacheId}`
  );
  // f8a22df073f07950ca695c1e90a1aeb8
  // d6b2b43f79b8e0590dead7b27e830468
  return axios.post(
    `${process.env.REACT_APP_DIMARC_URL}/services/extraction/async/bbf`,
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data',
        [config.token_type]: config.access_token,
      },
    }
  );
};

const scanOCR = async (file) => {
  const formData = new FormData();

  formData.append('document', file);
  // f8a22df073f07950ca695c1e90a1aeb8
  // d6b2b43f79b8e0590dead7b27e830468
  return axios.post(
    `https://api.mindee.net/v1/products/mindee/invoices/v3/predict`,
    // `https://api.mindee.net/v1/products/nampt/bbf/v1/predict`,
    formData,
    {
      timeout: 30 * 1000, // 30 seconds
      headers: {
        Authorization: `Token 90a48bbe6c7f63e675250b1ebcd05d4b`,
        'content-type': 'multipart/form-data',
      },
    }
  );
};

const cacheData = async (userId, data, fileName, fileSize, type) => {
  const result = await authInstance.post(
    `${process.env.REACT_APP_OCR_URL}/cache-data`,
    {
      user_id: userId,
      data,
      fileName,
      fileSize,
      type,
    }
  );
  return result.data;
};

const getCacheData = async (id) => {
  const cache = await authInstance.get(
    `${process.env.REACT_APP_OCR_URL}/cache-data/${id}`
  );
  return cache.data;
};

export default {
  recogniseReceipt,
  getOcrConfig,
  scanOCR,
  getOrcDimarcConfig,
  asyncScanORCwithDimarc,
  scanORCwithDimarc,
  cacheData,
  getCacheData,
};
