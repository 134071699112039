import { AppBar, Box, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import cl from 'classnames';
import PricingColumn from 'components/PricingColumn';
import { PricingTabList } from 'containers/Onboarding/utils';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import subscriptionService from 'services/SubscriptionService';
import spinnerService from 'services/SpinnerService';

import { MONTHLY, YEARLY } from 'constants/subscriptions';

import useStyles from './styles';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const getSubscriptionsByInterval = (subscriptions, interval) => {
  const obj = {};

  subscriptions.forEach((subscription) => {
    if (subscription.interval === interval) {
      const { name, hasAI } = subscription;

      const existingKey = Object.keys(obj).find(
        (key) => name.includes(key) || key.includes(name)
      );

      if (existingKey) {
        obj[existingKey] = { ...obj[existingKey], [hasAI]: subscription };
      } else {
        obj[name] = { [hasAI]: subscription };
      }
    }
  });

  return obj;
};

const AppSubscriptions = ({ handleChoosePricing }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const [subscriptions, setSubscriptions] = useState([]);

  const fetchSubscriptions = async () => {
    spinnerService.startSpinner();
    try {
      const data = await subscriptionService.getSubscriptions();
      if (data) {
        setSubscriptions(data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      spinnerService.endSpinner();
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const monthlySubscriptions = useMemo(
    () => getSubscriptionsByInterval(subscriptions, MONTHLY),
    [subscriptions]
  );

  const annualSubscriptions = useMemo(
    () => getSubscriptionsByInterval(subscriptions, YEARLY),
    [subscriptions]
  );

  return (
    subscriptions && (
      <div className={classes.root}>
        <div
          style={{
            marginBottom: 24,
            marginTop: 32,
          }}
        >
          <div className={classes.title}>{t('onboarding.pricing_title_1')}</div>
          <div className={classes.title}>{t('onboarding.pricing_title_2')}</div>
        </div>
        <AppBar className={classes.appBar} position="static" color="default">
          <Tabs
            className={classes.tabs}
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            TabIndicatorProps={{
              style: { display: 'none' },
            }}
          >
            {PricingTabList.map((item, index) => {
              return (
                <Tab
                  key={item}
                  label={t(`onboarding.${item}`)}
                  className={cl(
                    {
                      [classes.buttonActive]: index === value,
                    },
                    classes.button
                  )}
                />
              );
            })}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <div className={classes.pricingContainer}>
            {Object.keys(monthlySubscriptions)?.map((key) => (
              <PricingColumn
                key={key}
                handleChoosePricing={handleChoosePricing}
                subscriptions={monthlySubscriptions[key]}
                monthly
              />
            ))}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className={classes.pricingContainer}>
            {Object.keys(annualSubscriptions)?.map((key) => (
              <PricingColumn
                key={key}
                handleChoosePricing={handleChoosePricing}
                subscriptions={annualSubscriptions[key]}
              />
            ))}
          </div>
        </TabPanel>
      </div>
    )
  );
};

AppSubscriptions.propTypes = {
  handleChoosePricing: PropTypes.func,
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default AppSubscriptions;
