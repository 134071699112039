import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: '10px',
  },
  pricingContainer: {
    gap: '24px',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit,minmax(350px,350px))',
    justifyContent: 'center',
    width: '100%',
  },
  title: {
    color: theme.palette.grey[900],
    textAlign: 'center',
    fontSize: '38px',
    lineHeight: '46px',
    fontFamily: 'Marcellus',
  },
  tabs: {
    width: 'fit-content',
    margin: 'auto',
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: '8px',
    minHeight: '42px',
  },

  appBar: {
    backgroundColor: '#ffff',
    boxShadow: 'none',
  },
  button: {
    borderRadius: '8px',
    fontSize: '18px',
    fontFamily: 'Marcellus',
    lineHeight: '26px',
    textTransform: 'capitalize',
    minHeight: '42px',
    minWidth: '120px',
    color: theme.palette.grey[600],

    '&:hover': {
      color: theme.palette.grey[900],
    },
  },
  buttonActive: {
    color: '#ffffff !important',
    backgroundColor: theme.palette.secondary.light,
  },
}));

export default useStyles;
