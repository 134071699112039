import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import expenseServices from 'services/ExpenseService';
import s3FileService from 'services/S3FileService';

import { toTravel } from 'models/expense';
import { getFileExtension } from 'helpers/utils';

import { setGlobalError } from 'actions/GlobalError';

import logger from 'helpers/logger';

import {
  FETCH_EXPENSES,
  FETCH_EXPENSE,
  FETCH_EXPENSES_FAILED,
  CREATE_EXPENSE,
  UPDATE_EXPENSE,
  DELETE_EXPENSE,
  PENDING_EXPENSE,
  VALIDATE_EXPENSE,
  CANCEL_EXPENSE,
  PAY_EXPENSE,
  EXPENSE_VAT_LIST,
  EXPENSE_OVERVIEW,
  GET_EXPENSE_BY_CATEGORIES,
  GET_EXPENSE_BY_CATEGORIES_SUCCESS,
  GET_EXPENSE_BY_CATEGORIES_FAIL,
  REFRESH_EXPENSES_PAGINATE,
  FETCH_EXPENSES_PAGINATE,
  ADD_TRANSFERS_TO_EXPENSE,
  ADD_TRANSFERS_TO_EXPENSE_PAGINATE,
} from 'actions/Types';

import {
  userCanValidateExpense,
  userCanCreateExpense,
  userCanReadExpense,
  userCanUpdateExpense,
  userCanDeleteExpense,
  userCanOverviewExpense,
} from 'selectors/rightsSelector/expenseRequests';
import paginateAction from 'helpers/paginateAction';

const prepareAmounts = (values) => {
  const amounts = [];
  for (let i = 0; i < 4; i += 1) {
    if (values[`total${i}`] !== 0) {
      amounts.push({
        amount: values[`amount${i}`],
        total: values[`total${i}`],
        vat: values[`vat${i}`],
        vat_rate: values[`vatrate${i}`],
      });
    }
  }
  return amounts;
};

const prepareObject = (id, values, companyId, userId) => {
  const onCreation = {};
  if (!id) {
    onCreation.company_id = companyId;
    onCreation.user_id = userId;
  }

  const date = format(new Date(values.values.date), 'yyyy-MM-dd');

  const expenses = values.expenses.map((expense) =>
    toTravel({
      ...expense,
      amounts: expense?.amounts || prepareAmounts(expense),
      date: format(new Date(expense.date), 'yyyy-MM-dd'),
      client_id: expense.client_id || null,
      project_id: expense.project_id || null,
    })
  );

  return { ...values, ...onCreation, expenses, date };
};

export const fetchExpenses = (cb) => async (dispatch, getState) => {
  try {
    if (!userCanReadExpense(getState())) {
      dispatch({
        type: FETCH_EXPENSES,
        payload: [],
      });

      cb();
      return;
    }

    const { data } = await expenseServices.fetchExpenses();
    dispatch({
      type: FETCH_EXPENSES,
      payload: data,
    });
    if (cb) cb();
  } catch (error) {
    dispatch(setGlobalError(error));
    dispatch({ type: FETCH_EXPENSES_FAILED, payload: error });
    if (cb) cb(error);
  }
};

export const fetchExpense = (id, cb) => async (dispatch, getState) => {
  try {
    if (!userCanReadExpense(getState())) {
      cb();
      return;
    }

    const { data } = await expenseServices.fetchExpense(id);
    dispatch({ type: FETCH_EXPENSE, payload: data });
    cb();
  } catch (error) {
    cb(error.message);
  }
};

export const fetchExpensePdf = async (payload) => {
  try {
    const { data } = await expenseServices.fetchExpensePdf(payload);

    return data;
  } catch (error) {
    setGlobalError(error.message || '');
    return null;
  }
};

export const fetchExpenseFile = (filename, cb) => async (_, getState) => {
  try {
    if (!userCanReadExpense(getState())) {
      cb(null, {});
      return;
    }
    const { url, type } = await s3FileService.geturl(filename);

    cb(null, { url, type });
  } catch (error) {
    cb(error);
  }
};

export const createExpense = (values, cb) => async (dispatch, getState) => {
  try {
    const {
      loggedUser: { user },
      loggedUserCompany: { company },
    } = getState();

    if (!userCanCreateExpense(getState())) {
      cb();
      return null;
    }

    const data = prepareObject(null, values, company._id, user._id);

    // upload images
    await Promise.all(
      data.expenses
        .filter(({ filename }) => filename)
        .map((expense) => {
          /* eslint-disable-next-line */
          const file = expense.filename;
          const ext = getFileExtension(expense.filename.name);
          const folder = `company-files-${company.alternativeId}`;
          const fileName = `${uuidv4()}-${new Date().toISOString()}.${ext}`;

          /* eslint-disable-next-line */
          expense.filename = `${folder}/${fileName}`;
          return s3FileService.upload(file, folder, fileName);
        })
    );

    const response = (await expenseServices.createExpense(data)).data;

    if (cb && typeof cb === 'function') {
      cb(null, response._id);
    }
    return response;
  } catch (error) {
    if (cb && typeof cb === 'function') {
      cb(error);
    }
    return error;
  }
};

export const updateExpense = (id, values, cb) => async (dispatch, getState) => {
  try {
    const {
      loggedUserCompany: { company },
    } = getState();

    if (!userCanUpdateExpense(getState())) {
      cb();
      return;
    }

    const data = prepareObject(id, values);

    // upload images
    await Promise.all(
      data.expenses
        .filter(({ filename }) => filename && filename instanceof File)
        .map((expense) => {
          /* eslint-disable-next-line */
          const file = expense.filename;
          const ext = getFileExtension(expense.filename.name);
          const folder = `company-files-${company.alternativeId}`;
          const fileName = `${uuidv4()}-${new Date().toISOString()}.${ext}`;

          /* eslint-disable-next-line */
          expense.filename = `${folder}/${fileName}`;
          return s3FileService.upload(file, folder, fileName);
        })
    );

    const expense = (await expenseServices.updateExpense(id, data)).data;
    dispatch({ type: UPDATE_EXPENSE, payload: { id, expense } });

    if (cb && typeof cb === 'function') {
      cb(null, id);
    }
  } catch (error) {
    if (cb && typeof cb === 'function') {
      cb(error);
    }
  }
};

export const deleteExpense = (id, cb) => async (dispatch, getState) => {
  try {
    if (!userCanDeleteExpense(getState())) {
      cb();
      return;
    }

    await expenseServices.deleteExpense(id);
    dispatch({ type: DELETE_EXPENSE, payload: id });
    cb();
  } catch (error) {
    if (cb && typeof cb === 'function') {
      cb(error);
    }
  }
};

export const changeExpenseStatePending =
  (id, values, cb) => async (dispatch, getState) => {
    try {
      if (!userCanCreateExpense(getState())) {
        cb();
        return;
      }

      if (!id) {
        logger.log('createExpense');
        await dispatch(createExpense(values));
        /* eslint-disable-next-line */
        [id] = Object.keys(getState().expenses);
      }
      // else {
      //   await dispatch(updateExpense(id, values));
      // }

      const expense = (
        await expenseServices.changeStatePending(id, {
          deductibleAmount: values?.values?.deductibleAmount,
        })
      ).data;
      logger.debug('to pending', id);
      dispatch({ type: PENDING_EXPENSE, payload: { id, expense } });
      cb();
    } catch (error) {
      if (cb && typeof cb === 'function') {
        cb(error);
      }
    }
  };

export const validateExpenses = (ids, cb) => async (dispatch, getState) => {
  if (!userCanValidateExpense(getState())) {
    cb();
    return;
  }

  const errors = [];
  /* eslint-disable no-await-in-loop */
  for (let index = 0; index < ids.length; index += 1) {
    try {
      const id = ids[index];
      const { data } = await expenseServices.validateExpense(id);
      dispatch({ type: VALIDATE_EXPENSE, payload: { id, expense: data } });
    } catch (e) {
      errors.push(e);
    }
  }

  cb(errors.length ? errors : null);
};

export const cancelExpenses = (expenses, cb) => async (dispatch, getState) => {
  if (!userCanValidateExpense(getState())) {
    cb();
    return;
  }

  const errors = [];
  /* eslint-disable no-await-in-loop */
  for (let index = 0; index < expenses.length; index += 1) {
    try {
      const { id, refuseMessage } = expenses[index];
      const { data } = await expenseServices.cancelExpense(id, {
        refuseMessage,
      });
      dispatch({ type: CANCEL_EXPENSE, payload: { id, expense: data } });
    } catch (e) {
      errors.push(e);
    }
  }

  cb(errors.length ? errors : null);
};

export const payExpenses =
  (ids, transaction, cb) => async (dispatch, getState) => {
    if (!userCanValidateExpense(getState())) {
      cb();
      return;
    }

    const errors = [];
    /* eslint-disable no-await-in-loop */
    for (let index = 0; index < ids.length; index += 1) {
      try {
        const id = ids[index];
        const transactionIso = {
          ...transaction,
          date: new Date(transaction.date).toISOString(),
        };
        const { data } = await expenseServices.payExpense(id, transactionIso);
        dispatch({ type: PAY_EXPENSE, payload: { id, expense: data } });
      } catch (e) {
        errors.push(e);
      }
    }

    cb(errors.length ? errors : null);
  };

export const fetchExpensesVatList = () => (dispatch, getState) => {
  if (!userCanOverviewExpense(getState())) {
    return;
  }

  expenseServices
    .expensesVatList()
    .then(({ data }) => {
      dispatch({ type: EXPENSE_VAT_LIST, payload: data });
    })
    // eslint-disable-next-line no-console
    .catch((error) => {
      dispatch(setGlobalError(error));
    });
};

export const fetchExpenseOverview = () => (dispatch, getState) => {
  if (!userCanOverviewExpense(getState())) {
    return;
  }

  expenseServices
    .expenseOverview(getState().loggedUserCompany.company.endFiscalYear)
    .then(({ data }) => {
      dispatch({ type: EXPENSE_OVERVIEW, payload: data });
    })
    // eslint-disable-next-line no-console
    .catch((error) => {
      dispatch(setGlobalError(error));
    });
};

export const fetchExpenseByCategories = () => (dispatch, getState) => {
  if (!userCanOverviewExpense(getState())) {
    return;
  }
  dispatch({ type: GET_EXPENSE_BY_CATEGORIES });

  expenseServices
    .expenseByCategories(getState().loggedUserCompany.company.endFiscalYear)
    .then(({ data }) => {
      dispatch({ type: GET_EXPENSE_BY_CATEGORIES_SUCCESS, payload: data });
    })
    // eslint-disable-next-line no-console
    .catch((error) => {
      dispatch({ type: GET_EXPENSE_BY_CATEGORIES_FAIL, payload: error });
    });
};

export const createInvoiceExpense = (payload) => {
  return { type: CREATE_EXPENSE, payload };
};

export const updateInvoiceExpense = (id, expense) => {
  return { type: UPDATE_EXPENSE, payload: { id, expense } };
};

export const fetchExpensePaginate =
  (query, filters, refresh) => async (dispatch, getState) => {
    const { expensePaginate } = getState();
    const res = await paginateAction({
      state: expensePaginate,
      dispatch,
      service: expenseServices.fetchExpensePaginate,
      query,
      filters,
      refresh,
      actions: {
        refresh: REFRESH_EXPENSES_PAGINATE,
        fetch: FETCH_EXPENSES_PAGINATE,
      },
      queryCustom: { expenseIds: query?.expenseIds },
    });
    return res;
  };

export const addTransfersToExpense = (data) => ({
  type: ADD_TRANSFERS_TO_EXPENSE,
  payload: { ...data },
});

export const addTransfersToExpensePaginate = (data) => ({
  type: ADD_TRANSFERS_TO_EXPENSE_PAGINATE,
  payload: { ...data },
});

export const getExpensePaginateAction = (qs) =>
  expenseServices.fetchExpensePaginate(qs);
