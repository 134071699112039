import NumberFormat from './NumberFormat';

const handleDisplayCurrency = (value, decimalChar) => {
  const str = value?.toString().split(' ').join('') || '';
  if (str.includes(decimalChar)) {
    const arr = str.split(decimalChar);

    if (arr[1]) {
      return (
        `${NumberFormat.format(value, arr[1].length, decimalChar, ' ')}` || ''
      );
    }
    return value;
  }
  return `${NumberFormat.format(value, false, decimalChar, ' ')}` || '';
};
const CurrencyHelper = {
  handleDisplayCurrency,
};

export default CurrencyHelper;
