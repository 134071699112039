import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFormik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { map, isObject, find } from 'lodash';

import { Grid } from '@material-ui/core';
import spinnerService from 'services/SpinnerService';
import InternalActivityService from 'services/InternalActivityService';
import { globalDispatch } from 'store';
import { fetchInternalActivities } from 'actions/InternalActivitiesActions';
import capitalize from 'helpers/capitalize';
import { translateCategoryName } from 'helpers/providerHelpers';
import { PHONE_NUMBER_TEXT_REGEX } from 'constants/form';
import { SUB_CATEGORIES_PREFIX } from 'constants/expense';

import SelectAutoComplete from 'components/SelectAutocomplete';
import AppButton from '../../../components/AppButton';
import AppCompanySearch from '../../../components/AppCompany/AppCompanySearch';
import AppCheckbox from '../../../components/AppCheckbox';
import LoadingIndicator from '../../../components/LoadingIndicatorDialog';
import TextField from '../../../components/FormElements/textField';

import { validationSchema } from './validation';
import mapPropsToValues from './formstate';
import handleSubmit from './handleSubmit';

import state from './state';
import dispatch from './dispatch';

import styles from '../../../assets/jss/root';

import capitalizeForm from '../../../helpers/capitalizeForm';

const countryList = require('../../../assets/data/countryList.json');

class ProviderForm extends Component {
  constructor(props) {
    super(props);
    const { activities } = props;

    const keys = Object.keys(activities);
    const listActivity = keys.map((item) => activities[item]);
    this.state = {
      vat: !!props.values.intracomvat,
      tree: props.tree,
      selectedCategories: [],
      activities: listActivity,
      providersForm: props?.init,
      showMessageNoActivity: false,
      activityDisplay: '',
    };
  }

  componentDidMount() {
    const { values, setFieldValue, id } = this.props;
    const category = this.state.tree.find(
      (item) => item._id === values.category
    );
    if (category) {
      this.setState({ selectedCategories: category.children });
    }
    setFieldValue(
      'notRequiredSiret',
      (id && !values.siret) || values?.notRequiredSiret || false
    );
  }

  renderLoading(isSubmitting) {
    const { t } = this.props;
    if (isSubmitting) {
      return <LoadingIndicator title={t('loading_single')} open={true} />;
    }
    return null;
  }

  selectTitleAndSet(title) {
    const { setFieldValue } = this.props;
    if (title) {
      setFieldValue('title', title.value);
    } else {
      setFieldValue('title', '');
    }
  }

  displayTitleInCompanyMode() {
    const { t, values, errors, touched, canUpdateProvider } = this.props;
    const chooseTitles = [
      { label: 'M.', value: 'M.' },
      { label: 'Mme.', value: 'Mme.' },
    ];

    return (
      <Grid item xs={4}>
        <SelectAutoComplete
          name="title"
          label={t('client.title')}
          onChange={(e) => this.selectTitleAndSet(e)}
          getOptionLabel={(option) => option.label}
          values={chooseTitles}
          valueSelected={find(
            chooseTitles,
            (elem) => elem.value === values.title
          )}
          showError={errors.unit && touched.unit}
          error={errors.unit}
          disabled={!canUpdateProvider}
        />
      </Grid>
    );
  }

  displayName() {
    const {
      t,
      values,
      setFieldValue,
      fetchInfoGreffe,
      classes,
      canUpdateProvider,
    } = this.props;

    if (values.isCompany && canUpdateProvider) {
      return (
        <AppCompanySearch
          setFieldValue={setFieldValue}
          fetchInfoGreffe={fetchInfoGreffe}
          t={t}
          values={values}
          isClient
        />
      );
    }

    return (
      <TextField
        type="text"
        required
        name="name"
        label={t('providers.form.name')}
        placeholder={t('providers.form.name')}
        className={classes.textFieldWide}
        onChange={(e) => {
          capitalizeForm(e, setFieldValue);
        }}
        disabled={!canUpdateProvider}
      />
    );
  }

  computeIntracom() {
    const {
      values: { siret },
      setFieldValue,
    } = this.props;

    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(siret) && siret.length === 14) {
      const siren = siret.slice(0, 9);
      setFieldValue(
        'intracomvat',
        `FR ${(3 * (siren % 97) + 12) % 97}${siren}`
      );
    } else {
      setFieldValue('intracomvat', '');
    }
  }

  getActivities() {
    const { values } = this.props;
    if (this.state.activities) {
      const keys = Object.keys(this.state.activities);
      const listActivity = keys.map((item) => this.state.activities[item]);
      const activity = listActivity.find(
        (item) =>
          item?._id === values.activity ||
          item?.value === values.activity ||
          values.activity === item?.display
      );
      if (activity || !values.activity) {
        return listActivity;
      }

      const newActivities = [
        ...listActivity,
        {
          value: values.activity,
          display: values.activity,
          _id: values.activity,
        },
      ];
      this.setState({ activities: newActivities });
      return newActivities;
    }
    return [];
  }

  handleValueSelected() {
    const { values, isImport } = this.props;
    const listActivity = this.getActivities();
    let { activity } = values;
    if (isImport) {
      const findActivity = listActivity.find(
        (item) => item.display === values.activity
      );
      if (findActivity) {
        activity = findActivity._id;
      }
    }
    return find(this.getActivities(), (elem) => elem._id === activity);
  }

  handleSelectedSubCategoris() {
    const { values } = this.props;
    return find(
      this.state.selectedCategories,
      (elem) => elem._id === values.subCategory
    );
  }

  handleOnClickVat(checked) {
    this.setState({ vat: checked });
    if (!checked) {
      this.props.setFieldValue('intracomvat', '');
      return;
    }
    this.computeIntracom();
  }

  isIncludeInActivitiesState() {
    const { values } = this.props;
    const { activities } = this.state;

    const { activityDisplay } = values;
    let display = activityDisplay;

    if (display === undefined && values.activity) {
      const curActivity = activities.find(
        (item) => item._id === values.activity
      );
      display = curActivity?.display;
    }

    if (activities) {
      const keys = Object.keys(activities);
      const listActivity = keys.map((item) => activities[item]);

      const activityInTextField = listActivity.find(
        (item) => item.display === display
      );

      return !!activityInTextField;
    }

    return false;
  }

  isIncludeInActivities() {
    const { values, activities } = this.props;

    if (activities) {
      const keys = Object.keys(activities);
      const listActivity = keys.map((item) => activities[item]);

      const currActivity = listActivity.find(
        (item) => item?._id === values.activity
      );

      return !!currActivity;
    }

    return false;
  }

  async handleCreateActivity() {
    if (!this.props.values.activity) return;

    try {
      spinnerService.startSpinner();
      const { setFieldValue } = this.props;
      const keys = Object.keys(this.state.activities);

      const listActivity = keys.map((item) => this.state.activities[item]);

      const activity = listActivity.find(
        (item) => item._id === this.props.values.activity
      );
      if (activity) {
        const data = {
          description: activity.display,
          display: activity.display,
          value: activity.display.toLowerCase().replaceAll(' ', '_'),
          parentId: '',
        };
        const result = await InternalActivityService.createActivity(data);

        this.setState({
          activities: [
            ...this.state.activities.filter(
              (item) => item._id !== activity._id
            ),
            result.data,
          ],
        });
        setFieldValue('activity', result.data._id);

        globalDispatch(fetchInternalActivities());
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      spinnerService.resetSpinner();
    }
  }

  async handleCreateActivityByText(text) {
    if (!text) return;

    try {
      spinnerService.startSpinner();
      const { setFieldValue } = this.props;
      const data = {
        description: text,
        display: text,
        value: text.toLowerCase().replaceAll(' ', '_'),
        parentId: '',
      };
      const result = await InternalActivityService.createActivity(data);

      this.setState({
        activities: {
          ...this.state.activities,
          [result.data._id]: result.data,
        },
      });
      setFieldValue('activity', result.data._id);
      setFieldValue('activityDisplay', '');
      this.setState({ activityDisplay: '' });

      globalDispatch(fetchInternalActivities());
    } catch (error) {
      throw new Error(error);
    } finally {
      spinnerService.resetSpinner();
    }
  }

  handleTextFieldChange(value) {
    const { setFieldValue } = this.props;
    setFieldValue('activityDisplay', value);
    this.setState({ activityDisplay: value });
  }

  isExistedActivity() {
    const { values } = this.props;
    const activities = this.getActivities();
    const displays = activities.map((item) => item.display);
    return !!displays.find((item) => {
      return item.toLowerCase() === values?.activityDisplay?.toLowerCase();
    });
  }

  handleAddActivity() {
    const { values } = this.props;
    if (
      !this.isIncludeInActivities() &&
      values.activity &&
      this.isIncludeInActivitiesState()
    ) {
      this.handleCreateActivity();
    } else if (
      !this.isExistedActivity() &&
      values.activityDisplay &&
      !this.isIncludeInActivitiesState()
    ) {
      this.handleCreateActivityByText(values.activityDisplay);
    }
    this.setState({ showMessageNoActivity: false });
  }

  componentDidUpdate(prevProps, prevState) {
    const { values: preValues } = prevProps;
    const { values } = this.props;

    if (prevProps.values.siret !== this.props.values.siret) {
      this.computeIntracom();
    }

    const { init, setFieldValue } = this.props;
    if (prevProps.init !== init && !this.state.initializedFromInit) {
      if (init && Object.keys(init).length > 0) {
        Object.keys(init).forEach((key) => {
          setFieldValue(key, init[key]);
        });
        this.setState({ initializedFromInit: true });
      }
    }

    if (
      preValues.activity !== values.activity ||
      prevState.activityDisplay !== this.state.activityDisplay
    ) {
      if (
        !this.isIncludeInActivities() &&
        values.activity &&
        this.isIncludeInActivitiesState()
      ) {
        this.setState({ showMessageNoActivity: true });
      } else if (
        !this.isExistedActivity() &&
        values.activityDisplay &&
        !this.isIncludeInActivitiesState()
      ) {
        this.setState({ showMessageNoActivity: true });
      } else {
        this.setState({ showMessageNoActivity: false });
      }
    }
  }

  render() {
    const {
      t,
      errors,
      classes,
      closeDialog,
      isSubmitting,
      values,
      setFieldValue,
      importError,
      canUpdateProvider,
    } = this.props;

    return (
      <div className="section p-10">
        <Form>
          {this.renderLoading(isSubmitting)}
          {errors && errors.internalError && (
            <div className="row">
              <div className="input-field col s12">
                <span
                  className="helper-text"
                  style={{ color: 'red', padding: 4 }}
                >
                  {errors.internalError}
                </span>
              </div>
            </div>
          )}

          {importError && !errors?.internalError && (
            <div className="row">
              <div className="input-field col s12">
                <span
                  className="helper-text"
                  style={{ color: 'red', padding: 4 }}
                >
                  {t('providers.form.import_error')}
                </span>
              </div>
            </div>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} style={{ marginTop: '5px' }}>
              {this.displayName()}
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectAutoComplete
                required
                name="category"
                label={t('providers.form.category')}
                getOptionLabel={(elem) =>
                  translateCategoryName(elem.display, t)
                }
                onChange={(category) => {
                  if (category && category._id) {
                    setFieldValue('category', category._id);
                    this.setState(() => ({
                      selectedCategories: category.children,
                    }));
                  }
                }}
                values={this.state.tree}
                valueSelected={find(
                  this.state.tree,
                  (elem) => elem._id === values.category
                )}
                disabled={!canUpdateProvider}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectAutoComplete
                required
                name="subCategory"
                label={t('providers.form.subCategory')}
                getOptionLabel={(elem) =>
                  t(`${SUB_CATEGORIES_PREFIX}${elem.display}`)
                }
                onChange={(category) => {
                  if (category && category._id) {
                    setFieldValue('subCategory', category._id);
                  }
                }}
                values={this.state.selectedCategories}
                valueSelected={this.handleSelectedSubCategoris()}
                disabled={!canUpdateProvider}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 16,
                }}
              >
                <Grid item style={{ width: '75%' }}>
                  <SelectAutoComplete
                    name="activity"
                    label={t('settings_section.providers.activity')}
                    getOptionLabel={(elem) => elem.display}
                    onChange={(activity) => {
                      if (activity && activity.display) {
                        setFieldValue('activity', activity._id);
                        setFieldValue('activityDisplay', activity.display);
                      }
                    }}
                    handleTextFieldChange={(value) =>
                      this.handleTextFieldChange(value)
                    }
                    values={this.getActivities()}
                    valueSelected={this.handleValueSelected()}
                    inputValue={values.activityDisplay}
                    disabled={!canUpdateProvider}
                  />
                </Grid>
                <Grid item style={{ minWidth: 125 }}>
                  <AppButton
                    color="secondaryLight"
                    text={t('settings_section.providers.add_activity')}
                    onClick={() => {
                      this.handleAddActivity();
                    }}
                    isDisabled={
                      !values.activityDisplay ||
                      !this.state.showMessageNoActivity ||
                      !canUpdateProvider
                    }
                  />
                </Grid>
              </Grid>
              {this.state.showMessageNoActivity && (
                <div style={{ color: 'red' }}>
                  {t('settings_section.providers.no_activity')}
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="text"
                required
                name="address"
                label={t('providers.form.address')}
                placeholder={t('providers.form.address')}
                disabled={!canUpdateProvider}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                type="text"
                name="zipcode"
                label={t('providers.form.zip_code')}
                placeholder={t('providers.form.zip_code')}
                disabled={!canUpdateProvider}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                type="text"
                name="city"
                label={t('providers.form.city')}
                placeholder={t('providers.form.city')}
                disabled={!canUpdateProvider}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectAutoComplete
                required
                mandatory
                t={t}
                name="country"
                color="secondary"
                setWidthManualy="30%"
                placeholder={t('settings_section.clients.form_label_country')}
                label={t('settings_section.clients.form_label_country')}
                getOptionLabel={(elem) => {
                  if (!elem || (elem && isObject(elem) && !elem.alpha2)) {
                    return "Erreur d'initialisation";
                  }
                  return `${elem.name_fr} (${elem.alpha2})`;
                }}
                values={map(countryList, (ac) => ac)}
                valueSelected={
                  // eslint-disable-next-line no-undef
                  find(
                    countryList,
                    (elem) =>
                      elem.alpha2 === values.country ||
                      elem.name_fr?.toLowerCase() ===
                        values.country?.toLowerCase() ||
                      elem.alpha3 === values.country
                  ) || find(countryList, (elem) => elem.alpha2 === 'FR')
                }
                onChange={(country) => {
                  if (country && country.alpha2) {
                    setFieldValue('country', country.alpha2);
                    setFieldValue('countryAlpha3', country.alpha3);
                  }
                }}
                disabled={!canUpdateProvider}
              />
            </Grid>
            {values.isCompany && this.displayTitleInCompanyMode()}
            {values.isCompany && (
              <Grid item xs={12} sm={8}>
                <TextField
                  label={t('settings_section.clients.form_label_contact')}
                  placeholder={t('settings_section.clients.form_label_contact')}
                  color="secondary"
                  name="contactName"
                  onChange={(e) => {
                    capitalizeForm(e, setFieldValue);
                    setFieldValue('nomDuContact', capitalize(e.target.value));
                  }}
                  disabled={!canUpdateProvider}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={4}>
              <TextField
                setWidthManualy="32%"
                label={t('settings_section.clients.form_label_phone')}
                placeholder={t('settings_section.clients.form_label_phone')}
                color="secondary"
                name="phone"
                type="text"
                onChange={(e) => {
                  if (PHONE_NUMBER_TEXT_REGEX.test(e.target.value))
                    capitalizeForm(e, setFieldValue);
                }}
                disabled={!canUpdateProvider}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                setWidthManualy="64%"
                label={t('settings_section.clients.form_label_email')}
                placeholder={t('settings_section.clients.form_label_email')}
                color="secondary"
                name="email"
                disabled={!canUpdateProvider}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required={!values.notRequiredSiret}
                type="number"
                name="siret"
                label={t('providers.form.siret')}
                placeholder={t('providers.form.siret')}
                disabled={!canUpdateProvider}
              />
            </Grid>
            <Grid item xs={12}>
              <AppCheckbox
                onClick={(e) => {
                  setFieldValue('notRequiredSiret', e.target.checked);
                }}
                disabled={isSubmitting || !canUpdateProvider}
                checked={values.notRequiredSiret}
                labelText={t('providers.form.notRequiredSiret')}
                name="notRequiredSiret"
                id="notRequiredSiret"
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                // required
                type="text"
                name="iban"
                label={t('invoices.IBAN')}
                placeholder={t('invoices.IBAN')}
                disabled={!canUpdateProvider}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                // required
                type="text"
                name="bic"
                label={t('invoices.BIC')}
                placeholder={t('invoices.BIC')}
                disabled={!canUpdateProvider}
              />
            </Grid>
            <Grid item container xs={12} alignItems="center">
              <Grid item xs={4}>
                <AppCheckbox
                  onClick={(e) => {
                    this.handleOnClickVat(e.target.checked);
                  }}
                  disabled={isSubmitting || !canUpdateProvider}
                  checked={this.state.vat}
                  labelText={t('client.vat')}
                  name="vat"
                  id="vat"
                />
              </Grid>

              <Grid item xs={8}>
                {this.state.vat && (
                  <TextField
                    // required
                    type="text"
                    name="intracomvat"
                    label={t('providers.form.intracomvat')}
                    placeholder={t('providers.form.intracomvat')}
                    className={classes.textFieldWide}
                    disabled={!canUpdateProvider}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {canUpdateProvider && (
                <div className={classes.alignRight}>
                  {closeDialog && (
                    <AppButton
                      color="transparentGrey"
                      text={t('cancel')}
                      onClick={closeDialog}
                    />
                  )}

                  <AppButton
                    color="secondaryLight"
                    text={this.props.id ? t('update') : t('draft')}
                    noBorder={true}
                    disabled={
                      !this.isIncludeInActivities() &&
                      values.activity &&
                      this.isIncludeInActivitiesState()
                    }
                    type="submit"
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </Form>
      </div>
    );
  }
}

ProviderForm.propTypes = {
  classes: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  closeDialog: PropTypes.func,
  onCreated: PropTypes.func,
  status: PropTypes.object,
  values: PropTypes.object.isRequired,
  t: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  users: PropTypes.object,
  id: PropTypes.string,
  isSubmitting: PropTypes.bool,
  fetchInfoGreffe: PropTypes.func.isRequired,
  tree: PropTypes.array,
  activities: PropTypes.object,
  init: PropTypes.object,
  importError: PropTypes.bool,
  isImport: PropTypes.bool,
  canUpdateProvider: PropTypes.bool,
};

const ProviderFormMemo = React.memo(ProviderForm);

const UserFormWithFormik = withFormik({
  displayName: 'ProviderForm',
  enableReinitialize: true,
  mapPropsToValues,
  validationSchema,
  handleSubmit,
})(ProviderFormMemo);

const UserFormWithStyles = withStyles(styles)(UserFormWithFormik);

const TranslatedUserFormWithStyles = withTranslation()(UserFormWithStyles);

export default connect(state, dispatch)(TranslatedUserFormWithStyles);
