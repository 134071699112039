import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ConfirmDialog from 'components/ConfirmDialog';
import Layout from '../../../components/Layout';
import ClientHeader from '../components/ClientHeader';
import ClientImportBody from '../Table/ClientImportBody';
import ClientFormDialog from '../components/ClientFormDialog';
import ProjectFormDialog from '../components/ProjectFormDialog';
import ProjectDeleteDialog from '../components/ProjectDeleteDialog';
import ClientImportDeleteDialog from '../components/ClientImportDeleteDialog';
import useImportAction from '../hooks/useImportActions';
import useImportClients from '../hooks/useImportClients';

const ClientImport = (props) => {
  const { t } = useTranslation();
  const { onFileChange, clients, checkValidateData } = useImportClients(
    props.isCompany,
    t
  );

  const {
    handleToggleDialog,
    handleOnConfirm,
    handleDeleteRow,
    openClientFormDialog,
    closeClientFormDialog,
    closeProjectFormDialog,
    showDeleteProjectDialog,
    closeDeleteProjectDialog,
    closeDeleteClientDialog,
    handleUpdateClient,
    handleSave,
    state: {
      confirmMessage,
      isShowDialog,
      confirmType,
      isCancelConfirm,
      showClientDialog,
      selectedClientId,
      showProjectDialog,
      showClientDeleteDialog,
      showProjectDeleteDialog,
      selectedProjectId,

      showSpinner,
    },
  } = useImportAction(
    clients.clientList,
    clients.setClientList,
    checkValidateData
  );

  function renderBody() {
    return (
      <Fragment>
        <ProjectDeleteDialog
          showDialog={showProjectDeleteDialog}
          closeDialog={closeDeleteProjectDialog}
          selectedClientId={selectedClientId}
          selectedProjectId={selectedProjectId}
        />
        <ClientImportDeleteDialog
          showDialog={showClientDeleteDialog}
          closeDialog={closeDeleteClientDialog}
          selectedClientId={selectedClientId}
          handleDeleteRow={handleDeleteRow}
        />

        <ClientFormDialog
          showDialog={showClientDialog}
          selectedClientId={selectedClientId}
          closeClientFormDialog={closeClientFormDialog}
          clients={clients}
          updateClientImport={handleUpdateClient}
        />
        <ProjectFormDialog
          showDialog={showProjectDialog}
          selectedClientId={selectedClientId}
          selectedProjectId={selectedProjectId}
          closeProjectFormDialog={closeProjectFormDialog}
          showDeleteDialog={showDeleteProjectDialog}
        />

        <ClientImportBody
          openClientFormDialog={openClientFormDialog}
          handleToggleDialog={handleToggleDialog}
          clients={clients}
          onFileChange={onFileChange}
          handleDeleteRow={handleDeleteRow}
          isCompany={props.isCompany}
          handleSave={handleSave}
          showSpinner={showSpinner}
        />
      </Fragment>
    );
  }

  return (
    <>
      <ConfirmDialog
        toggleDialog={handleToggleDialog}
        confirmMessage={confirmMessage}
        isShowDialog={isShowDialog}
        type={confirmType}
        onConfirm={handleOnConfirm}
        isCancelConfirm={isCancelConfirm}
      />
      <Layout
        header={
          <ClientHeader
            openClientFormDialog={openClientFormDialog}
            isCreateActions={false}
            screenType={props.screenType}
          />
        }
        sidebarLeft={true}
        sidebarRight={null}
        showUserCard={true}
        body={renderBody()}
      />
    </>
  );
};

ClientImport.propTypes = {
  isCompany: PropTypes.bool,
  screenType: PropTypes.string,
};

export default ClientImport;
