import React, { useState, useEffect, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useLocation, withRouter } from 'react-router-dom';
import { withFormik, Form } from 'formik';
import { connect, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, Grid } from '@material-ui/core';
import _ from 'lodash';

/* Import helpers */
import PaymentTypes from 'helpers/PaymentTypes';
import logger from 'helpers/logger';

/* Import components */
import Layout from 'components/Layout';
import AppPlusDivider from 'components/AppPlusDivider';
import Header from 'components/Header';
import {
  ExpensesFormSidebar,
  FormActions,
  ReadOnlyTable,
} from 'components/Expenses';
import ExpenseFormComponent from 'components/Expenses/NewForm/ExpenseForm';
import LoadingIndicatorDialog from 'components/LoadingIndicatorDialog';
import AppDialog from 'components/AppDialog';
import { withUnmounted } from 'components/HighOrderComponents';
import ClientDialog from 'components/ClientDialog';
import ProjectCreationModal from 'containers/Project/createProjectModal';
import AppButton from 'components/AppButton';
import BankTransferFormik from 'components/CardComponents/Treezor/BankTransferForm/BankTransferFormik';
import TransactionDialog from 'components/TransactionDialog';
import { TRANSACTION_TYPE } from 'constants/banks';
import ExpensePdf from 'containers/ExpensesPage/Pdf';

/* Import external resources */
import { TreezorBeneficiaryTypes } from '@bebusinessfocus/compta-hub-core';

/* Import assets */
import styles from 'assets/jss/root';
import { AppDividerXS } from 'components/AppDividers';
import { useScanOcr } from 'hooks/scanOcr/useScanOcr';
import { useCreateDialog } from 'hooks/dialogHooks';
import { ProviderFormDialog } from 'containers/purchase/PurchaseForm/Dialogs';
import style from 'assets/jss/components/appDialog';
import ConfirmDialog from 'components/ConfirmDialog';
import { EXPENSE_TYPE } from 'constants/expense';

/* Import redux parts */
import state from './state';
import dispatch from './dispatch';

/* Import formik parts */
import validationSchema from './validation';
import mapPropsToValues from './formstate';
import handleSubmit from './handleSubmit';
import CancelExpenseFormDialog from '../Components/CancelExpenseFormDialog';

const ExpenseForm = (props) => {
  const {
    t,
    errors,
    isSubmitting,
    setFieldValue,
    activities,
    history,
    fetchExpenseFile,
    clients,
    clientProjects,
    handleSubmit: formikHanldeSubmit,
    match: {
      params: { id: expenseId },
    },
    categories: categoriesRoot,
    validateForm,
    isValidating,
    userCanValidate,
    isMounted,
    loggedUser: { user },
    userCanReadExpense,
    users,
    expenses,
  } = props;

  let { values } = props;
  const { resetForm } = props;

  if (!values) {
    values = { loading: true };
  }

  const readOnly =
    ['canceled', 'validated', 'pending', 'paid'].includes(values.state) ||
    (expenseId && user._id !== values.user_id) ||
    (expenseId && !userCanReadExpense);
  const edit = !!expenseId;
  const urlParams = new URLSearchParams(window.location.search);
  const cacheId = urlParams.get('cacheId');

  const [openConfirm, setOpenConfirm] = useState(false);
  const [isSetCategory, setIsSetCategory] = useState(false);
  const [showClientFormDialog, setClientFormDialog] = useState(false);
  const [showProjectFormDialog, setProjectFormDialog] = useState(false);
  const [showApprovalDialog, setApprovalDialogVisibility] = useState(false);
  const [sendIsClicked, toggleSendClick] = useState(false);
  const [showExpenseCancelDialog, setShowExpenseCancelDialog] = useState(false);
  const [validateIsClicked, toggleValidateClick] = useState(false);
  const [PayIsClicked, togglePayClick] = useState(false);
  const [error, setError] = useState(null);
  const [alert, setAlert] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [formType, setFormType] = useState('ndf');
  const [selectedCategory, setSelectedCategory] = useState({});
  const [isFetchingExpense, setIsFetchingExpense] = useState(false);
  const [showTransactionDialog, setShowTransactionDialog] = useState(false);
  const [isOwner, setIsOwner] = useState(false);

  const { walletId } = useSelector((reduxState) =>
    reduxState.wallet &&
    reduxState.wallet.wallet &&
    reduxState.wallet.wallet.length > 0
      ? reduxState.wallet.wallet[0]
      : {}
  );

  const companyAccounts = useSelector((reduxState) =>
    reduxState.loggedUserCompany.companies
      ? reduxState.loggedUserCompany.companies
      : {}
  );
  const providers = useSelector((reduxState) => reduxState.providers);
  const company = useSelector((states) => states.loggedUserCompany.company);

  const {
    showDialog: showNewProviderDialog,
    toggleDialog: toggleNewProviderDialog,
    newItem: newProvider,
    setNewItem: setNewProvider,
    removeNewItem: removeNewProvider,
  } = useCreateDialog();

  const { onOcr, ocrError, asyncScanOcr, getValuesFromCache } = useScanOcr({
    setFieldValue,
    values,
    toggleNewProviderDialog,
    type: EXPENSE_TYPE.EXPENSE,
  });

  const paymentTypes = _.filter(
    PaymentTypes,
    (type) => type._id === 'personal'
  );

  // Pay dialog
  const [payDialog, setPayDialog] = useState(false);

  const handleOpenPayDialog = () => {
    setPayDialog(true);
  };

  const handleClosePayDialog = () => {
    setPayDialog(false);
  };

  function toggleAlertErrorMessage() {
    logger.debug('toggleAlertErrorMessage()');
    setAlert(!alert);
  }

  function toggleClientFormDialog() {
    logger.debug('toggleClientFormDialog()');
    if (isMounted) {
      setClientFormDialog(!showClientFormDialog);
    }
  }

  function toggleProjectFormDialog() {
    logger.debug('toggleProjectFormDialog()');
    if (isMounted) {
      setProjectFormDialog(!showProjectFormDialog);
    }
  }

  function toggleApprovalDialog() {
    logger.debug('toggleApprovalDialog()');
    if (isMounted) {
      setApprovalDialogVisibility(!showApprovalDialog);
    }
  }

  function onSave(e) {
    logger.debug('onSave()');
    if (isMounted) {
      setFieldValue('draft', true, false);

      setTimeout(() => {
        logger.debug('formikHanldeSubmit()', e);
        formikHanldeSubmit(e);
      }, 100);
    }
  }

  function onSendSubmit() {
    logger.debug('onSendSubmit()');
    if (isMounted) {
      toggleApprovalDialog();
      logger.debug('toggleSendClick(true)');
      toggleSendClick(true);
    }
  }

  function onValidateSubmit() {
    logger.debug('onValidateSubmit()');
    if (isMounted) {
      toggleApprovalDialog();
      logger.debug('toggleValidateClick(true)');
      toggleValidateClick(true);
    }
  }

  function onPaySubmit() {
    logger.debug('onPaySubmit()');
    if (isMounted) {
      // toggleApprovalDialog();
      setShowTransactionDialog(true);
      logger.debug('togglePayClick()');
      togglePayClick(true);
    }
  }

  function onCancelSubmit() {
    logger.debug('onCancelSubmit()');
    if (isMounted) {
      setShowExpenseCancelDialog(true);
      logger.debug('toggleCancelClick()');
    }
  }

  function isErrorsEmpty(errorObject) {
    logger.debug('isErrorsEmpty()', errorObject);
    if (errorObject == null) return true;
    if (errorObject.length > 0) return false;
    if (errorObject.length === 0) return true;
    if (typeof errorObject !== 'object') return true;
    if (Object.getOwnPropertyNames(errorObject).length > 0) return false;
    return true;
  }

  function onSend(e) {
    logger.debug('onSend()');
    if (isMounted) {
      setFieldValue('draft', false, false);
      setFieldValue('action', 'send');
      setTimeout(() => {
        toggleApprovalDialog();
        logger.debug('formikHanldeSubmit()', e);
        formikHanldeSubmit(e);

        validateForm().then(() => {
          if (!isErrorsEmpty(errors)) {
            toggleAlertErrorMessage();
          }
        });
      }, 100);
    }
  }

  function onValidate(e) {
    logger.debug('onValidate()');
    if (isMounted) {
      setFieldValue('validate', true, false);
      setFieldValue('action', 'send');

      setTimeout(() => {
        toggleApprovalDialog();
        logger.debug('formikHanldeSubmit()', e);
        formikHanldeSubmit(e);
      }, 100);
    }
  }

  function onPay(e) {
    logger.debug('onPay()');
    if (isMounted) {
      setFieldValue('pay', true, false);
      setFieldValue('action', 'send');

      setTimeout(() => {
        // toggleApprovalDialog();
        logger.debug('formikHanldeSubmit()', e);
        formikHanldeSubmit(e);
      }, 100);
    }
  }

  function onCancel({ refuseMessage }) {
    logger.debug('onCancel()');
    if (isMounted) {
      setFieldValue('cancel', true, false);
      setFieldValue('action', 'send');
      setFieldValue('refuseMessage', refuseMessage);
      setTimeout(() => {
        setShowExpenseCancelDialog(false);

        formikHanldeSubmit();
      }, 100);
    }
  }

  function onCloseDialog() {
    logger.debug('onCloseDialog()');
    if (isMounted) {
      toggleApprovalDialog();
      toggleSendClick(false);
      toggleValidateClick(false);
      togglePayClick(false);
    }
  }

  function toggleFetchingExpense() {
    logger.debug('toggleFetchingExpense() preValue', isFetchingExpense);
    setIsFetchingExpense((prevValue) => !prevValue);
  }

  function onExpenseFetched(err) {
    logger.debug('onExpenseFetched() error:', err);
    setError(err);
    toggleFetchingExpense();
  }

  const fetchExpenseCallback = useCallback(props.fetchExpense);

  const group = props.location.state ? props.location.state.group : null;

  useEffect(() => {
    if ((expenseId && !expenses[expenseId]) || (group && !expenses[group])) {
      toggleFetchingExpense();
      fetchExpenseCallback(expenseId || group, onExpenseFetched);
    }
    if (user && expenseId && expenses && expenses[expenseId]) {
      setIsOwner(expenses[expenseId].user_id === user.id);
    }
  }, [expenseId, expenses, fetchExpenseCallback]);

  useEffect(() => {
    if (cacheId) {
      getValuesFromCache(cacheId);
    }
  }, [cacheId]);

  // handle preview pdf goto /expenses/pdf/:id/preview in the new tab
  // const handlePreviewPdf = () => {
  //   window.open(`/expenses/pdf/${expenseId}/preview`, '_blank');
  // };

  /*
   *
   * RENDER FUNCTIONS
   *
   */

  function renderAlertErrorMessage() {
    const text =
      'Veuillez vérifier que toutes vos lignes de frais soient bien valides.';
    if (alert) {
      return (
        <AppDialog
          title="Erreur de validation"
          closeDialog={toggleAlertErrorMessage}
          iconClose
          onConfirmText={t('yes')}
          onConfirm={toggleAlertErrorMessage}
          contentText={text}
        ></AppDialog>
      );
    }
    return '';
  }

  function renderLoadingIndicator() {
    const open =
      isValidating ||
      isSubmitting ||
      categoriesRoot.loading ||
      isFetchingExpense;
    return (
      <LoadingIndicatorDialog open={!!open} title={t('invoices.procesing')} />
    );
  }

  function renderApproveActionDialog() {
    if (showTransactionDialog) {
      return (
        <TransactionDialog
          toggleDialog={() => {
            setShowTransactionDialog(!showTransactionDialog);
          }}
          onSubmit={(transaction) => {
            setFieldValue('transaction', transaction);
            setShowTransactionDialog(false);
            onPay();
          }}
          defaultAmount={values.duePayableAmount || values.total}
          type={TRANSACTION_TYPE.DEBIT}
          documentId={values?.expenses.map((item) => item._id)}
        />
      );
    }
    if (showApprovalDialog) {
      const text =
        (sendIsClicked && t('invoice.confirm.create')) ||
        (validateIsClicked && t('invoice.confirm.validate')) ||
        (PayIsClicked && t('invoice.confirm.paid'));

      const action =
        (sendIsClicked && onSend) ||
        (validateIsClicked && onValidate) ||
        (PayIsClicked && onPay);

      return (
        <AppDialog
          title={t('sure')}
          closeDialog={onCloseDialog}
          sm
          iconClose
          onConfirmText={t('yes')}
          onCancelText={t('no')}
          footer
          onConfirm={action}
          contentText={text}
          color="secondary"
        />
      );
    }
    return null;
  }

  function renderHeader() {
    const location = useLocation();

    const goBackPath = location.state?.goBackPath;
    const goBackQueryParams = location.state?.goBackQueryParams;
    const pageTitle = location.state?.pageTitle;

    return (
      <Header
        name={t('expenses.expense.expense')}
        spaceBetween
        goBack={() => {
          if (isSetCategory) {
            setIsSetCategory(false);
            resetForm();
          } else {
            // history.push('/expenses/list');
            history.push({
              pathname: goBackPath || '/expenses/list',
              search: goBackQueryParams,
              state: {
                pageTitle,
              },
            });
          }
        }}
      />
    );
  }

  function renderContent() {
    if (edit) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <ExpensePdf expenseId={expenseId} />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ReadOnlyTable
              expense={values}
              users={users}
              activities={activities}
              clients={clients.data}
              clientProjects={clientProjects.projects}
              categories={categoriesRoot}
              fetchExpenseFile={fetchExpenseFile}
            />

            {/* expense action */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              {values.state === 'draft' && isOwner && (
                <AppPlusDivider
                  fullWidth={false}
                  onClick={() => {
                    setIsSetCategory(false);
                    resetForm();
                    history.push('/expenses/new', { group: expenseId });
                  }}
                  message={'Ajouter une ligne de frais'}
                />
              )}
              <FormActions
                errors={errors}
                horizontal
                // readOnly={readOnly}
                userCanValidate={userCanValidate}
                isOwner={isOwner}
                state={values.state}
                onSave={onSave}
                onSend={onSendSubmit}
                onPay={onPaySubmit}
                onValidate={onValidateSubmit}
                onCancel={onCancelSubmit}
                isSubmitting={isSubmitting}
                t={t}
              />
            </div>
          </Grid>
        </Grid>
      );
    }

    return (
      <Form noValidate autoComplete="off">
        <div style={{ marginBottom: '20px' }}>
          {errors && errors.internalError && (
            <div className={'error'}>{errors.internalError}</div>
          )}
          {error && <div className={'error'}>{error}</div>}
          {/* {isSetCategory ? renderForm() : renderCategories()} */}
          <ExpenseFormComponent
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            // defaultTaxes={selectedCategory.vat && selectedCategory.vat.vatRates}
            defaultTaxes={[]}
            // isDistanceRate={selectedCategory.travel.isTravelWithDistanceRate} // good
            isDistanceRate={false}
            isPurchase={false} // good
            paymentTypes={paymentTypes}
            onSelectFormType={setFormType}
            onOcr={onOcr}
            ocrError={ocrError}
            setOpenConfirmScan={setOpenConfirm}
          />
        </div>

        {/* {values.category_id && ( */}
        {values.category_id && (
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <FormActions
              newExpense={!expenseId}
              errors={errors}
              horizontal
              readOnly={readOnly}
              userCanValidate={userCanValidate}
              state={values.state}
              onSave={onSave}
              onSend={onSendSubmit}
              onPay={onPaySubmit}
              isSubmitting={isSubmitting}
              t={t}
            />
          </div>
        )}

        {/* )} */}
      </Form>
    );
  }

  const renderPayDialog = () => {
    let source = {};
    let userOId;
    let prefill = {};
    if (expenseId && Object.entries(expenses).length !== 0) {
      const { transfers } = expenses[expenseId];
      let totalPayoutAmount = 0;

      if (transfers && transfers.length > 0) {
        // eslint-disable-next-line
        transfers.map((transfer) => {
          const { payoutId } = transfer;

          if (payoutId && typeof payoutId === 'object') {
            const { amount } = payoutId;

            totalPayoutAmount += Number(amount);
          }
        });
      }

      source = {
        id: expenseId,
        type: 'expense',
        filename: expenses[expenseId].filename,
      };

      userOId =
        expenses[expenseId] && expenses[expenseId].user_id
          ? expenses[expenseId].user_id
          : null;

      prefill = {
        beneficiaryType: expenses[expenseId] ? 'user' : null,
        beneficiary: userOId ? users[userOId] : null,
        amount:
          Object.entries(expenses).length !== 0
            ? expenses[expenseId].total - totalPayoutAmount
            : 0,
      };
    }

    return (
      <Dialog
        maxWidth="md"
        open={payDialog}
        onClose={handleClosePayDialog}
        aria-labelledby="pay-dialog-title"
      >
        <DialogTitle id="pay-dialog-title">{t(`Refund expenses`)}</DialogTitle>
        <DialogContent>
          <BankTransferFormik
            expenseId={expenseId}
            providers={[
              ..._.values(providers).map((provider) => ({
                ...provider,
                id: provider._id,
              })),
            ]}
            clients={[
              ..._.values(clients.data).map((client) => ({
                ...client,
                id: client._id,
              })),
            ]}
            users={[
              ..._.values(users).map((ele) => ({
                ...ele,
                id: ele._id,
              })),
            ]}
            companyAccounts={[
              ...companyAccounts.map((account) => ({
                ...account,
                id: account._id,
              })),
            ]}
            beneficiaryTypes={TreezorBeneficiaryTypes}
            source={source}
            prefill={prefill}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const navigate = (path) => {
    history.push(path);
  };

  const handleConfirmOcr = async (isConfirm) => {
    if (!isConfirm) {
      await onOcr();
      return;
    }
    await asyncScanOcr(EXPENSE_TYPE.EXPENSE);
    navigate('/expenses/list');
  };

  function renderBody() {
    return (
      <Fragment>
        {renderApproveActionDialog()}
        {renderLoadingIndicator()}
        {renderAlertErrorMessage()}
        <CancelExpenseFormDialog
          showDialog={showExpenseCancelDialog}
          closeFormDialog={() => setShowExpenseCancelDialog(false)}
          handleSubmitCancel={onCancel}
        />
        {payDialog && renderPayDialog()}
        {showClientFormDialog && (
          <ClientDialog t={t} closeDialog={toggleClientFormDialog} />
        )}

        <ProviderFormDialog
          showNewProviderDialog={showNewProviderDialog}
          toggleNewProviderDialog={toggleNewProviderDialog}
          setNewProvider={setNewProvider}
          stylePaper={style.positionRight}
        />

        {showProjectFormDialog && (
          <ProjectCreationModal
            show={toggleProjectFormDialog}
            isEdit={false}
            onClose={() => {
              if (isMounted) {
                setProjectFormDialog(!showProjectFormDialog);
              }
            }}
            clients={clients.data}
            companyId={company._id}
          />
        )}
        <ConfirmDialog
          toggleDialog={setOpenConfirm}
          confirmMessage={t('expenses.ocr_confirm.description')}
          title={t('expenses.ocr_confirm.title')}
          isShowDialog={openConfirm}
          confirmText={t('expenses.ocr_confirm.wait_btn')}
          cancelText={t('expenses.ocr_confirm.return_btn')}
          // type={confirmType}
          onConfirm={handleConfirmOcr}
          isCancelConfirm={true}
        />

        {renderContent()}
      </Fragment>
    );
  }
  // sidebar
  function renderSidebar() {
    let refundButton = null;

    if (Object.entries(expenses).length !== 0) {
      if (
        expenseId &&
        expenses[expenseId] &&
        expenses[expenseId].state === 'validated' &&
        walletId
      ) {
        const { transfers } = expenses[expenseId];
        let totalPayoutAmount = 0;

        if (transfers && transfers.length > 0) {
          // eslint-disable-next-line
          transfers.map((transfer) => {
            const { payoutId } = transfer;

            if (payoutId && typeof payoutId === 'object') {
              const { amount } = payoutId;

              totalPayoutAmount += Number(amount);
            }
          });
        }

        if (
          totalPayoutAmount < expenses[expenseId].total ||
          totalPayoutAmount < expenses[expenseId].grandTotalAmount
        ) {
          refundButton = (
            <>
              <AppDividerXS />

              <AppButton
                type="button"
                color="primary"
                noBorder={true}
                text={t('expenses.overview.refund')}
                onClick={handleOpenPayDialog}
              />
            </>
          );
        }
      }
    }
    return refundButton || !readOnly ? (
      <div>
        <ExpensesFormSidebar
          readOnly={readOnly}
          userCanValidate={userCanValidate}
          state={values.state}
          onSave={onSave}
          onSend={onSendSubmit}
          onValidate={onValidateSubmit}
          onPay={onPaySubmit}
          onCancel={onCancelSubmit}
          showClientFormDialog={toggleClientFormDialog}
          showProjectFormDialog={toggleProjectFormDialog}
          category={values.category_id}
          newExpense={!expenseId}
          isSubmitting={isSubmitting}
          t={t}
          newProvider={{
            provider: newProvider,
            removeNewProvider,
          }}
          toggleNewProviderDialog={toggleNewProviderDialog}
        />
        {refundButton}
      </div>
    ) : null;
  }

  /*
   *
   * MAIN RENDER
   *
   */

  return (
    <Layout
      header={renderHeader()}
      sidebarLeft={true}
      sidebarTop={renderSidebar()}
      body={renderBody()}
      showUserCard={true}
    />
  );
};

ExpenseForm.propTypes = {
  // style
  classes: PropTypes.object.isRequired,
  // state
  expenses: PropTypes.object.isRequired,
  activities: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  clients: PropTypes.object.isRequired,
  clientProjects: PropTypes.object.isRequired,
  distanceRates: PropTypes.object.isRequired,
  vatRates: PropTypes.array.isRequired,
  match: PropTypes.object,
  changeExpenseStatePending: PropTypes.func,
  userCanValidate: PropTypes.bool,
  userCanReadExpense: PropTypes.bool,
  validateExpenses: PropTypes.func,
  isMounted: PropTypes.bool,
  fetchExpenseFile: PropTypes.func,
  fetchExpense: PropTypes.func,
  loggedUser: PropTypes.object.isRequired,
  // formik
  resetForm: PropTypes.func,
  validateForm: PropTypes.func,
  touched: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  values: PropTypes.object, // null when edit
  errors: PropTypes.object,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  isValidating: PropTypes.bool.isRequired,
  setSubmitting: PropTypes.func,
  // i18n
  t: PropTypes.func,
  // router
  history: PropTypes.object,
  location: PropTypes.object,
  // others
  users: PropTypes.object,
};

const ExpensesWithFormik = withFormik({
  displayName: 'ExpenseFormwithFormik',
  enableReinitialize: true,
  mapPropsToValues,
  validationSchema,
  handleSubmit,
})(ExpenseForm);

const ExpenseFormWithStyles = withStyles(styles)(ExpensesWithFormik);

const ExpenseFormTranslated = withTranslation()(ExpenseFormWithStyles);

const ExpenseFormWithRouter = withRouter(ExpenseFormTranslated);

const ExpenseFormWithUmounted = withUnmounted(ExpenseFormWithRouter);

export default connect(state, dispatch)(ExpenseFormWithUmounted);
