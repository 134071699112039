import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Grid, Table, TableBody, makeStyles } from '@material-ui/core';

import { useFormikContext } from 'formik';

import CurrencyTextField from 'components/FormElements/CurrencyTextField';
import calculateTotals from 'helpers/purchaseHelper';
import { useSelector } from 'react-redux';
import CurrencyHelper from 'helpers/currencyHelper';
import { useDecimalCharacter } from 'hooks/decimalCharacterHooks';
import PurchaseVATline from '../PurchaseVATline';

const useStyles = makeStyles({
  root: {
    paddingTop: '0px',
    paddingBottom: '0px',
    borderBottom: 'none',
  },
  currencyInput: {
    padding: '10.5px 10px 10.5px 0',
  },
});

export default function PurchaseVATGroup({
  readOnly = false,
  noVAT = false,
  categories,
  isSimpleForm,
  defaultTaxes,
}) {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();
  const fullVatRates = useSelector((state) => state.fullVatRates);
  const { decimalChar } = useDecimalCharacter();

  const classes = useStyles();
  useEffect(() => {
    const { taxBasisTotalAmount, taxTotalAmount } = calculateTotals(
      values.amounts
    );

    const grandTotalAmount = (
      Number(taxBasisTotalAmount) + Number(taxTotalAmount)
    ).toFixed(2);
    setFieldValue('taxBasisTotalAmount', taxBasisTotalAmount);
    setFieldValue('grandTotalAmount', grandTotalAmount);
    setFieldValue('duePayableAmount', grandTotalAmount);
    setFieldValue('taxTotalAmount', taxTotalAmount);
    setFieldValue('totalForm', grandTotalAmount);
  }, [values.amounts]);

  const renderTotalVAT = () => {
    return (
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '8px',
          }}
        >
          <p className={classes.root} style={{ width: '30%' }}>
            {t('expenses.expense.form.total_net')}
          </p>
          <div className={classes.root}>
            <CurrencyTextField
              style={{ margin: 0 }}
              name={'taxBasisTotalAmount'}
              placeholder={'0,00'}
              required={values?.state}
              value={CurrencyHelper.handleDisplayCurrency(
                values?.taxBasisTotalAmount || '0',
                decimalChar
              )}
              type="text"
              disabled
            />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '8px',
          }}
        >
          <p className={classes.root} style={{ width: '30%' }}>
            {t('expenses.expense.form.vat_amount')}
          </p>
          <div className={classes.root}>
            <CurrencyTextField
              style={{ margin: 0 }}
              name={'taxTotalAmount'}
              placeholder={'0,00'}
              disabled={true}
              required={values?.state}
              value={CurrencyHelper.handleDisplayCurrency(
                values?.taxTotalAmount || '0',
                decimalChar
              )}
              type="text"
            />
          </div>
        </div>
      </div>
    );
  };
  // If WithoutVat is True We have no visible vat line
  useEffect(() => {
    if (noVAT) {
      setFieldValue('amounts', []);
    }
  }, []);

  return (
    <Grid container spacing={3} justifyContent="flex-start" alignItems="center">
      <Grid item xs={12}>
        <PurchaseVATline
          readOnly={readOnly}
          categories={categories}
          vatRates={isSimpleForm ? defaultTaxes : fullVatRates}
          isSimpleForm={isSimpleForm}
        />
      </Grid>
      <Grid item xs={12}>
        {!noVAT && (
          <Grid container>
            <Grid item xs={readOnly ? 12 : 10}>
              <Table aria-label="total details">
                <TableBody>
                  {renderTotalVAT()}

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      padding: '8px',
                    }}
                  >
                    <p style={{ width: '30%' }} className={classes.root}>
                      {t('expenses.expense.form.total_gross')}
                    </p>
                    <div className={classes.root}>
                      <CurrencyTextField
                        style={{ margin: 0 }}
                        name={'grandTotalAmount'}
                        placeholder={'0,00'}
                        disabled={true}
                        value={CurrencyHelper.handleDisplayCurrency(
                          values?.grandTotalAmount || '0',
                          decimalChar
                        )}
                        type="text"
                      />
                    </div>
                  </div>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={readOnly ? 0 : 2}></Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

PurchaseVATGroup.propTypes = {
  readOnly: PropTypes.bool,
  noVAT: PropTypes.bool,
  defaultTaxes: PropTypes.array,
  numberOfVatLine: PropTypes.number,
  categories: PropTypes.array,
  isSimpleForm: PropTypes.bool,
};
