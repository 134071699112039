// expense form link
export const GOOGLE_DIRECTION_BASE_URL =
  'https://www.google.com/maps/dir/?api=1&';

// Comapny ID request header
export const X_COMPANY_ID = 'X-Company-Id';

// export const COMPANY_ID = 'companyId';

export const DEFAULT_PAGESIZE = 10;

// Aut0
export const ID_TOKEN = 'id_token';
export const ACCESS_TOKEN = 'access_token';
export const EXPIRES_AT = 'expires_at';
export const STATE = 'state';
export const NONCE = 'nonce';
// export const LAST_UPDATED = 'last_updated';

// Book keeping / accounting
export const COMMITMENT = 'commitment';
export const CASH = 'cash';

// field of activity
export const PRESENTATION = 'prestation';
export const VENTE = 'vente';

// tax system
export const BNC = true;
export const BIC = false;

// Treezor
export const TREEZOR_VALUE = 'TreezorValue';

// Blocked PIN status
export const BLOCK_PIN = 1;

// Default category ID
export const DEFAULT_CATEGORY_ID = '5d1cc13d529fc3c8af0b9f9f';

// Fake stripe session_id
export const STRIPE_PRICING_TRIAL_SECTION = 'trial_section';

// Export file type
export const EXPORT_FILE_TYPE = '.csv';
export const EXPORT_BOOK_TYPE = 'csv';
export const EXPORT_FILE_CONTENT_TYPE = 'text/csv';

// Localstorage
export const LOCAL_STORAGE_PERSONAL_INFO = 'personalInfo';
export const LOCAL_STORAGE_SUBSCRIPTION = 'register_subscription';

// File type
export const PDF_MEDIA_TYPE = 'application/pdf';
export const OCTET_STREAM_MEDIA_TYPE = 'application/octet-stream';

export const EXCEL_TEMPLATE_URL = {
  PROVIDER: `${process.env.REACT_APP_EXCEL_TEMPLATE_URL}/template_import_provider.xlsx`,
  CLIENT_PERSONAL: `${process.env.REACT_APP_EXCEL_TEMPLATE_URL}/template-import-client_particulier.xlsx`,
  CLIENT_COMPANY: `${process.env.REACT_APP_EXCEL_TEMPLATE_URL}/template-import-client_professionnel.xlsx`,
  ITEM_LIST: `${process.env.REACT_APP_EXCEL_TEMPLATE_URL}/template-import-items-list.xlsx`,
};

export const ONBOARDING_DOCUMENT = {
  CGC: `${process.env.REACT_APP_ONBOARDING_DOCUMENT}/CGV-CGU_BBF.docx`,
  ANTI_MONEY_LAUNDERING: `${process.env.REACT_APP_ONBOARDING_DOCUMENT}/Procédure-de-lutte-contre-le-blanchiement-et-de-financement-du-terrorisme-LCBFT.pdf`,
  ANNEXE_ONE: `${process.env.REACT_APP_ONBOARDING_DOCUMENT}/ANNEXE1.pdf`,
};

export const FRANCE_COUNTRY_CODE = 'FR';

export const FOOTER_DOCUMENT = {
  CONDITIONS: `${process.env.REACT_APP_ONBOARDING_DOCUMENT}/conditions-generales-de-vente-bbg-outil-de-gestion-23-08-24.pdf`,
  POLITIQUE: `${process.env.REACT_APP_ONBOARDING_DOCUMENT}/politique-de-confidentialite-bbf-23-aout-2024.pdf`,
};

export const NEW_ONBOARDING_URL = `${process.env.REACT_APP_ONBOARDING_UI}/register-as`;
