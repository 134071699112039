import React, { Fragment, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { map } from 'lodash';
import MUIDataTable from 'mui-datatables';
import { fetchUserCards } from 'helpers/cardHelpers';
import { lockUnlockCard } from 'actions/CardActions';
import { cancelTreezorUser } from 'actions/UserTreezorActions';
import FormLoading from 'components/CardComponents/Treezor/FormLoading';
import { useSelector } from 'react-redux';
import spinnerService from 'services/SpinnerService';
import { fetchCompanySubscription } from 'actions/CompanySubscription';

import {
  PROCESSING_CANCEL_TREEZOR_USER,
  SUCCESS_CANCEL_TREEZOR_USER,
} from 'actions/Types';
import AppPlusSelect from 'components/AppPlusSelect';
import Layout from '../../../components/Layout';
import { useRouter } from '../../../hooks/routerHooks';

import {
  IconDelete,
  IconEdit,
  IconRefresh,
} from '../../../components/AppIcons';
import { AppRowStartSpaceAround } from '../../../components/AppContainers';
import AppDialog from '../../../components/AppDialog';
import Header from '../../../components/Header';

import Form from '../Form';

import { getMuiDatatableOptions } from '../../../helpers/tableHelpers';

import { useThunkDispatch } from '../../../hooks/generalHooks';

import {
  userCanCreateUserManagement,
  userCanUpdateUserManagement,
  userCanDeleteUserManagement,
} from '../../../selectors/rightsSelector/userManagementRequests';

import { fetchCompanyUsers, deleteUser } from '../../../actions/UserActions';
import AddUserFormDialog from '../components/AddUserFormDialog';

const UserDeleteDialog = ({ showDialog, closeDialog, selectedUserId }) => {
  const { t } = useTranslation();
  const dispatch = useThunkDispatch();
  const { treezorUsers } = useSelector((state) => state.treezor);

  useEffect(() => {
    spinnerService.startSpinner();
    Promise.all([
      dispatch(fetchCompanyUsers()),
      dispatch(fetchCompanySubscription()),
    ]).finally(() => spinnerService.endSpinner());
  }, []);

  const deleteClientCbBBF = useCallback(
    () =>
      dispatch(
        deleteUser(selectedUserId, () => {
          dispatch({ type: SUCCESS_CANCEL_TREEZOR_USER });
          closeDialog();
        })
      ),
    [dispatch, selectedUserId]
  );

  const deleteClientCb = async () => {
    dispatch({ type: PROCESSING_CANCEL_TREEZOR_USER });
    if (treezorUsers.length > 0) {
      const result = await fetchUserCards(selectedUserId);
      await dispatch(cancelTreezorUser(selectedUserId));
      if (result && result.data && result.data.cards) {
        for (let i = 0; i < result.data.cards.length; i += 1) {
          const data = {
            lockStatus: 1,
          };
          // eslint-disable-next-line no-await-in-loop
          await dispatch(lockUnlockCard(result.data.cards[i]._id, data));
        }
        await dispatch(cancelTreezorUser(selectedUserId));
      }
    }
    deleteClientCbBBF();
  };

  if (showDialog) {
    return (
      <AppDialog
        title={t('sure')}
        closeDialog={closeDialog}
        sm
        onConfirmText={t('yes')}
        onCancelText={t('no')}
        footer
        iconClose
        onConfirm={deleteClientCb}
        contentText={t('settings_section.clients.delete_user_content')}
        color="secondary"
      />
    );
  }

  return null;
};

const UserFormDialog = ({
  showDialog,
  selectedUserId,
  closeUserFormDialog,
  openAddUserFormDialog,
}) => {
  const { t } = useTranslation();

  if (showDialog) {
    return (
      <AppDialog
        title={
          selectedUserId
            ? t('settings_section.users.edit_title')
            : t('settings_section.users.create_title')
        }
        closeDialog={closeUserFormDialog}
        onConfirmType="submit"
        onConfirmText="submit"
        iconClose
        color="secondary"
        //   setHeightManualy="650px"
      >
        <Form
          id={selectedUserId}
          closeDialog={closeUserFormDialog}
          openAddUserFormDialog={openAddUserFormDialog}
        />
      </AppDialog>
    );
  }

  return null;
};

const UserListHeader = ({ showFormDialog, history }) => {
  const { t } = useTranslation();
  const dispatch = useThunkDispatch();
  const createUserManagement = useSelector(userCanCreateUserManagement);

  const fetchCompanyUsersCb = useCallback(() => {
    dispatch(fetchCompanyUsers());
    dispatch(fetchCompanySubscription());
  }, [dispatch]);
  const types = [
    {
      name: t('settings_section.users.create_title'),
      onClick: () => showFormDialog(),
    },
  ];

  return (
    <Header
      name={t('settings_section.users.title')}
      spaceBetween
      goBack={() => {
        history.goBack();
      }}
    >
      <IconRefresh onClick={fetchCompanyUsersCb} />
      {createUserManagement && (
        <AppPlusSelect items={types} name="name" label={t('create')} />
      )}
    </Header>
  );
};

const UserRole = ({ userRole }) => {
  const companyAccessRights = useSelector((state) => state.companyAccessRights);

  return (
    <div>
      {companyAccessRights.roles
        .filter((role) => userRole.find((r) => role._id === r))
        .map((role) => (
          <div key={role._id}>{role.role}</div>
        ))}
    </div>
  );
};

const UserListBody = ({ showDeleteDialog, openUserFormDialog }) => {
  const { t } = useTranslation();

  const loggedUser = useSelector((state) => state.loggedUser.user);
  const users = map(useSelector((state) => state.users)).map((user) => ({
    id: user._id,
    // just when created user doesn't have a display_name
    name: user.display_name ? user.display_name : user.name,
    description: user.description,
    createdAt: user.created_at,
    role: user.role,
  }));

  const canUpdateUserManagement = useSelector(userCanUpdateUserManagement);
  const canDeleteUserManagement = useSelector(userCanDeleteUserManagement);

  const columns = [
    {
      label: t('settings_section.users.table_name'),
      name: 'name',
    },
    {
      label: t('settings_section.users.table_description'),
      name: 'description',
    },
    {
      label: t('settings_section.users.table_role'),
      name: 'role',
      options: {
        // eslint-disable-next-line react/display-name
        customBodyRender: (value) => {
          return <UserRole userRole={value} />;
        },
      },
    },
    {
      label: t('settings_section.users.table_date_add_on'),
      name: 'createdAt',
      options: {
        customBodyRender: (value) => {
          if (value) {
            return format(new Date(value), 'dd/MM/yyyy');
          }
          return '';
        },
      },
    },
    {
      label: ' ',
      name: 'id',
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        search: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value) => {
          if (loggedUser._id === value) {
            return;
          }

          // eslint-disable-next-line consistent-return
          return (
            <AppRowStartSpaceAround>
              {canDeleteUserManagement && (
                <IconDelete sm onClick={() => showDeleteDialog(value)} />
              )}

              {canUpdateUserManagement && (
                <IconEdit
                  sm
                  onClick={() => {
                    openUserFormDialog(value);
                  }}
                />
              )}
            </AppRowStartSpaceAround>
          );
        },
      },
    },
  ];

  return (
    <MUIDataTable
      data={users}
      columns={columns}
      options={getMuiDatatableOptions(t, users.length)}
    />
  );
};

const BuyQuotationSuccessfully = ({ showDialog, closeFormDialog }) => {
  const { t } = useTranslation();

  if (showDialog) {
    return (
      <AppDialog
        title={t('settings_section.users.buy_quota_success_title')}
        closeDialog={closeFormDialog}
        onConfirmType="submit"
        onConfirmText="submit"
        iconClose
        color="secondary"
      >
        <div>{t('settings_section.users.buy_quota_success')}</div>
      </AppDialog>
    );
  }

  return null;
};

const UserList = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [showFormDialog, setShowFormDialog] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showAddUserFormDialog, setShowAddUserFormDialog] = useState(false);
  const [showUserDeleteDialog, setShowUserDeleteDialog] = useState(false);
  const [inviteFormData, setInviteFormData] = useState();
  const { loading } = useSelector((state) => state.treezor);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get('status');
  const { history } = useRouter();

  function openUserFormDialog(userId = null) {
    setShowFormDialog(true);
    setSelectedUser(userId);
  }

  function openAddUserFormDialog(data) {
    setInviteFormData(data);
    setShowAddUserFormDialog(true);
  }

  function toggleDeleteUserDialog() {
    setShowUserDeleteDialog((prevValue) => !prevValue);
  }

  function closeUserFormDialog() {
    setShowFormDialog(false);
    setSelectedUser(null);
  }

  function openSuccessDialog() {
    setShowSuccessDialog(true);
  }

  function closeSuccessDialog() {
    queryParams.delete('status', 'success');
    history.replace({ search: queryParams.toString() });
    setShowSuccessDialog(false);
  }

  function closeAddUserFormDialog() {
    setShowAddUserFormDialog(false);
  }

  function showDeleteUserDialog(userId) {
    toggleDeleteUserDialog();
    setSelectedUser(userId);
  }

  function closeDeleteUserDialog() {
    toggleDeleteUserDialog();
    setSelectedUser(null);
  }

  useEffect(() => {
    if (status === 'success') {
      openSuccessDialog();
    }
  }, []);

  function renderBody() {
    return (
      <Fragment>
        <UserDeleteDialog
          showDialog={showUserDeleteDialog}
          closeDialog={closeDeleteUserDialog}
          selectedUserId={selectedUser}
        />

        <BuyQuotationSuccessfully
          showDialog={showSuccessDialog}
          closeFormDialog={closeSuccessDialog}
        />

        <UserFormDialog
          showDialog={showFormDialog}
          selectedUserId={selectedUser}
          closeUserFormDialog={closeUserFormDialog}
          openAddUserFormDialog={openAddUserFormDialog}
        />

        <AddUserFormDialog
          openAddUserFormDialog={openAddUserFormDialog}
          closeAddUserFormDialog={closeAddUserFormDialog}
          inviteFormData={inviteFormData}
          showDialog={showAddUserFormDialog}
        />

        <UserListBody
          showDeleteDialog={showDeleteUserDialog}
          openUserFormDialog={openUserFormDialog}
        />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <FormLoading loading={loading} />
      <Layout
        header={
          <UserListHeader
            showFormDialog={openUserFormDialog}
            history={history}
          />
        }
        sidebarLeft={true}
        sidebarRight={null}
        showUserCard={true}
        body={renderBody()}
      />
    </Fragment>
  );
};

UserListHeader.propTypes = {
  showFormDialog: PropTypes.func,
  history: PropTypes.object.isRequired,
};

UserRole.propTypes = {
  userRole: PropTypes.array,
};

UserListBody.propTypes = {
  showDeleteDialog: PropTypes.func,
  openUserFormDialog: PropTypes.func,
};

UserDeleteDialog.propTypes = {
  showDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  selectedUserId: PropTypes.string,
};

UserFormDialog.propTypes = {
  showDialog: PropTypes.bool,
  closeUserFormDialog: PropTypes.func,
  selectedUserId: PropTypes.string,
  openAddUserFormDialog: PropTypes.func,
};

BuyQuotationSuccessfully.propTypes = {
  showDialog: PropTypes.bool,
  closeFormDialog: PropTypes.func,
};

export default UserList;
