import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import InvoiceListBody from './InvoiceListBody';
import RevenueListBody from './RevenueListBody';
import { useCheckInvoiceLimited } from '../../../hooks/invoiceHooks';

const Body = ({ canReadInvoice, refreshing }) => {
  const { t } = useTranslation();
  const { invoiceLimited } = useCheckInvoiceLimited();

  return (
    <Fragment>
      {invoiceLimited && (
        <Typography variant="subtitle1" color="error">
          {t('invoices.errors.invoice_limit')}
        </Typography>
      )}
      <Typography variant="h4">{t('invoices.your_invoices')}</Typography>
      <InvoiceListBody
        canReadInvoice={canReadInvoice}
        refreshing={refreshing}
      />

      <br />
      {/* this divier is a bit useless. todo: add a margin bottom on the invoice body list */}
      {/* <Divider variant="middle" /> */}
      <br />

      <Typography variant="h4">{t('revenues.your_revenues')}</Typography>
      <RevenueListBody refreshing={refreshing} />
    </Fragment>
  );
};

Body.propTypes = {
  canReadInvoice: PropTypes.bool.isRequired,
  refreshing: PropTypes.bool.isRequired,
};

export default Body;
