import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { DEFAULT_CATEGORY_ID } from 'config/Constants';
import { userCanUpdateProvider } from 'selectors/rightsSelector/providerRequests';
import { setProvider } from 'helpers/providerHelpers';
import CustomGenericDialog from '../../../../../components/CustomGenericDialog';
import ProviderForm from '../../../../ProvidersPage/Form';

const ProviderFormDialog = ({
  showNewProviderDialog,
  toggleNewProviderDialog,
  setNewProvider,
  stylePaper,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const { t } = useTranslation();
  const canUpdateProvider = useSelector(userCanUpdateProvider);
  const activities = useSelector((state) => state.activities);
  const tree = useSelector((state) => state.categories.tree);

  const filteredTree = !tree
    ? []
    : [
        ...tree
          .find((category) => category._id === DEFAULT_CATEGORY_ID)
          .children.filter(
            (category) => !category.travel.isTravelWithDistanceRate
          ),
        ...tree.filter((category) => category.order > 0),
      ].map((item) => ({
        display: t(`expenses.categories.${item.display}`),
        children: item.children,
        _id: item._id,
      }));

  if (!showNewProviderDialog) {
    return null;
  }

  const handleCloseDialog = () => {
    toggleNewProviderDialog();
    setFieldValue('importError', false);
  };

  const handleCreated = (providers) => {
    const provider = providers[0];
    setNewProvider(provider);
    setFieldValue('importError', false);

    if (providers.length) {
      setFieldValue('fetchProviders', true);
      const { amounts } = values;
      if (amounts?.length > 0) {
        const amountsWithCategory = amounts.map((item) => ({
          ...item,
          category_id: provider.category,
          sub_category_id: provider.subCategory,
        }));
        setFieldValue('amounts', amountsWithCategory);
      }

      if (values?.withOcr) {
        const { prixTTC = 0, prixHT = 0, prixTVA = 0 } = values;

        setFieldValue('grandTotalAmount', prixTTC);
        setFieldValue('taxBasisTotalAmount', prixHT);
        setFieldValue('grandTotalAmount', prixTTC);
        setFieldValue('duePayableAmount', prixTTC);
        setFieldValue('taxTotalAmount', prixTVA);
        setFieldValue('totalForm', prixTTC);

        setFieldValue('withOcr', false);
      }

      setProvider(provider, setFieldValue, values, filteredTree, t);
      setFieldValue(
        'description',
        `${provider.name}${
          values?.billNumber ? ` N° ${values?.billNumber}` : ''
        }`
      );
    }
  };

  return (
    <CustomGenericDialog
      title={t('provider.dialog_title')}
      color="secondary"
      iconClose
      closeDialog={handleCloseDialog}
      stylePaper={stylePaper}
    >
      <ProviderForm
        closeDialog={handleCloseDialog}
        onCreated={handleCreated}
        tree={filteredTree}
        activities={activities}
        init={values?.providerForm}
        importError={values?.importError || false}
        canUpdateProvider={canUpdateProvider}
      />
    </CustomGenericDialog>
  );
};

ProviderFormDialog.propTypes = {
  showNewProviderDialog: PropTypes.bool.isRequired,
  toggleNewProviderDialog: PropTypes.func.isRequired,
  setNewProvider: PropTypes.func.isRequired,
  stylePaper: PropTypes.object,
};

export default ProviderFormDialog;
