/* Import helpers */
import logger from 'helpers/logger';
import { authInstance as http } from 'helpers/axiosInterceptor';
import { EXPENSE_STATE } from 'constants/expense';

/* Import services */
import PDFService from 'services/PDFService';
import { getPayloadSavePurchase, uploadFile } from '../view/action';

const onSubmit = async (
  { action, ...values },
  { setError, setFieldValue, setSubmitting, props }
) => {
  let createResponseId = null;

  // TODO: only insert here
  // insert or update
  const upsertPurchase = async (id, val) => {
    if (id) {
      const { data } = await http.put(
        `${process.env.REACT_APP_EXPENSES_URL}/purchases/${id}`,
        val
      );
      props.updateInvoiceExpense(id, data);
    } else {
      const { data } = await http.post(
        `${process.env.REACT_APP_EXPENSES_URL}/purchases`,
        val
      );

      createResponseId = data._id;

      const { id: expenseOId, __t } = data;

      // If create expense by using splitted PDF file, update SplitPDFHistory collection
      const splitId = new URLSearchParams(window.location.search).get(
        'splitId'
      );

      if (splitId) {
        await PDFService.updateAfterCreateExpense({
          splitPDFHistoryId: splitId,
          expenseOId,
          expenseType: __t,
        });
      }

      // If create expense by using deposit PDF file, mark deposit file is used file
      const depositId = new URLSearchParams(window.location.search).get(
        'depositId'
      );

      if (depositId) {
        await PDFService.markAsUsedDepositPoolItemFile({
          depositPDFPoolId: depositId,
          isUsed: true,
          expenseId: expenseOId,
        });
      }

      props.createInvoiceExpense(data);
    }
  };

  // mark expense as paid
  const payPurchase = async (id, val) => {
    await upsertPurchase(id, val);
    const { data } = await http.post(
      `${process.env.REACT_APP_EXPENSES_URL}/expenses/${id}/paid`
    );
    props.updateInvoiceExpense(id, data);
  };

  try {
    const {
      loggedUserCompany: { company },
    } = props;
    const { id: expenseId } = props.match.params;

    setSubmitting(true);
    logger.log('Formik values', values);

    const filename = await uploadFile(values, { setFieldValue }, company);

    const data = getPayloadSavePurchase({
      ...values,
      filename,
      state: EXPENSE_STATE.DRAFT,
    });

    logger.log('API data', data);

    if (action === 'upsert') {
      await upsertPurchase(expenseId, data);
    } else if (action === 'pay') {
      await payPurchase(expenseId, data);
    }

    const goBackPath = props.location?.state?.goBackPath;
    const goBackQueryParams = props.location?.state?.goBackQueryParams;
    const pageTitle = props.location?.state?.pageTitle;

    props.history.push({
      pathname: `/purchases/edit/${createResponseId}` || '/purchases/list',
      state: {
        notShowReconclilier: Boolean(createResponseId),
        goBackPath,
        goBackQueryParams,
        pageTitle,
      },
    });
  } catch (e) {
    setError(e.message);
    setSubmitting(false);
    setFieldValue('action', null);
    setFieldValue('state', 'draft');
  } finally {
    setFieldValue('action', null);
    setSubmitting(false);
  }
};

export default onSubmit;
