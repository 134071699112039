import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { useSelector } from 'react-redux';
import _, { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

import DragNDropZone from 'components/DragNDropZone';
import UploadInstructions from 'components/UploadInstructions';
import LoadingIndicatorDialog from 'components/LoadingIndicatorDialog';
import { getCategoryName } from 'helpers/providerHelpers';
import { debounceFn } from 'helpers/utils';
import SelectedRowsMessage from 'components/SelectedRowsMessage';
import AppButton from '../../../components/AppButton';
import { IconEdit, IconDelete } from '../../../components/AppIcons';
import { AppRowCenterCenter } from '../../../components/AppContainers';
import ConfirmDialog from '../../../components/ConfirmDialog';

import { getMuiDatatableOptions } from '../../../helpers/tableHelpers';
import useImportProviders from '../hooks/useImportProviders';
import useImportAction from '../hooks/useImportAction';
// import ImportValidationWarning from '../../../components/ImportValidationWarning';
import { tableFrame } from '../../../helpers/mui-datatableHelper';

const useStyles = makeStyles({
  uploadContent: {
    textAlign: 'center',
  },
});

const ProviderImportedBody = ({ openProviderFormDialog, updatedProvider }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const categories = useSelector((state) => state.categories);
  const activities = useSelector((state) => state.activities);

  const activityIds = Object.keys(activities);

  // custom hook to import providers
  const { onFileChange, providers, checkValidateData } = useImportProviders();

  const {
    handleDeleteRow,
    handleEditProvider,
    handleSave,
    handleToggleDialog,
    handleOnConfirm,
    handleImportProviderTableData,
    // updateProviderSelected,
    getProviderSelected,
    getSubCategory,
    state: {
      confirmType,
      isCancelConfirm,
      isShowDialog,
      confirmMessage,
      showSpinner,
    },
  } = useImportAction(
    providers,
    updatedProvider,
    openProviderFormDialog,
    checkValidateData
  );

  const tableColumns = useMemo(() => {
    let columns = [];
    const columnsHeader = [
      { label: t('providers.form.company_name'), name: 'name' },
      { label: t('providers.form.siret'), name: 'siret' },
      { label: t('providers.form.form_label_activity'), name: 'activity' },
      { label: t('providers.form.category'), name: 'category' },
      { label: t('providers.form.subCategory'), name: 'subCategory' },
      { label: t('providers.form.form_label_contact'), name: 'nomDuContact' },
      { label: t('providers.form.form_label_phone'), name: 'phone' },
      { label: t('providers.form.form_label_email'), name: 'email' },
      { label: t('providers.form.intracomvat'), name: 'intracomvat' },
    ];
    const headerStyles = {
      textAlign: 'left',
      paddingLeft: '16px',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
      fontWeight: '500',
    };

    columns = tableFrame(columnsHeader, headerStyles);
    columns.push({
      label: ' ',
      name: 'index',
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        search: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (idx) => {
          return (
            <div>
              <AppRowCenterCenter>
                <div>
                  <IconEdit
                    sm
                    onClick={() => {
                      handleEditProvider(idx);
                    }}
                  />
                  <IconDelete
                    sm
                    onClick={() => {
                      handleDeleteRow(idx);
                    }}
                  />
                </div>
              </AppRowCenterCenter>
            </div>
          );
        },
      },
    });
    return columns;
  }, [
    openProviderFormDialog,
    providers.providerList,
    handleEditProvider,
    handleDeleteRow,
  ]);

  const options = {
    selectableRows: true,
    onRowSelectionChange: (val) => {
      if (!providers.providerList) {
        return;
      }
      if (!val || !val.length) {
        providers.providerList.forEach((elem) => {
          // eslint-disable-next-line no-param-reassign
          elem.setSelected(false);
        });
        // return;
      } else if (
        val.length > 1 &&
        providers.providerList.length === val.length
      ) {
        providers.providerList.forEach((elem) => {
          // eslint-disable-next-line no-param-reassign
          elem.setSelected(true);
        });
        // return;
      } else if (val.length === 1) {
        const [{ index }] = val;
        providers.providerList[index].toggleSelected();
      }
      // updateProviderSelected();
    },
    // eslint-disable-next-line no-shadow
    setRowProps: (_, index) => {
      // const existedActivity = activityIds.includes(
      //   providers.providerList[index].activity
      // );

      if (!providers.providerList || !providers.providerList[index]) {
        return '';
      }
      // const isValidate =
      //   providers.providerList[index].isValidated && existedActivity;
      const isValidate = providers.providerList[index].isValidated;
      return {
        style: isValidate
          ? {}
          : { border: '4px solid', borderColor: '#ff0000' },
      };
    },
    isRowSelectable: (dataIndex) => {
      // const existedActivity = activityIds.includes(
      //   providers.providerList[dataIndex].activity
      // );
      if (!providers.providerList && !providers.providerList[dataIndex]) {
        return false;
      }
      // const isValidate =
      //   providers.providerList[dataIndex].isValidated && existedActivity;
      const isValidate = providers.providerList[dataIndex].isValidated;
      return isValidate;
    },
    rowsSelected: getProviderSelected().map((item) => item.index),
  };

  const activitiesProvider = providers.providerList.map(
    (item) => item?.activity
  );

  // eslint-disable-next-line no-unused-vars
  const existedActivities = !_.isEmpty(
    _.intersection(activityIds, activitiesProvider)
  );

  const providersList = handleImportProviderTableData(
    providers.providerList
  ).map((provider) => ({
    ...provider,
    subCategory: getSubCategory(provider),
    category: getCategoryName(provider, categories, t),
  }));

  return (
    <React.Fragment>
      <ConfirmDialog
        toggleDialog={handleToggleDialog}
        confirmMessage={confirmMessage}
        isShowDialog={isShowDialog}
        type={confirmType}
        onConfirm={handleOnConfirm}
        isCancelConfirm={isCancelConfirm}
      />

      {!isEmpty(providers.providerList) ? (
        <div>
          <div style={{ textAlign: 'right', marginTop: '8px' }}>
            {showSpinner && (
              <LoadingIndicatorDialog title={t('loading_single')} open={true} />
            )}

            <AppButton
              // isDisabled={isEmpty(selectedRows)}
              style={{ marginRight: '8px' }}
              color="secondaryLight"
              text={t('create_income')}
              onClick={debounceFn(() => handleSave(), 500)}
            />
          </div>
          {/* {providers.providerList.some(
            (provider) => !provider._isValidated
          ) && <ImportValidationWarning />} */}
          <SelectedRowsMessage
            message={t('settings_section.providers.hint_import')}
          />
          <MUIDataTable
            data={providersList}
            columns={tableColumns}
            options={getMuiDatatableOptions(
              t,
              providers.providerList.length,
              options
            )}
          />
        </div>
      ) : (
        <div className={classes.uploadContent}>
          <UploadInstructions type="provider" />
          <DragNDropZone
            chooseFile={onFileChange}
            acceptFile=".xlsx"
            isMultiple={false}
          />
        </div>
      )}
    </React.Fragment>
  );
};

ProviderImportedBody.propTypes = {
  openProviderFormDialog: PropTypes.func,
  chooseFile: PropTypes.func,
  updatedProvider: PropTypes.object,
};

export default ProviderImportedBody;
