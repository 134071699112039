const { makeStyles } = require('@material-ui/core');

const useStyles = makeStyles((theme) => ({
  priceColumn: {
    borderRadius: '16px',
    border: `1px solid ${theme.palette.grey[300]}`,
    height: 'fit-content',
    fontFamily: 'Kumbh Sans',
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '16px 16px 0px 0px',
    padding: 20,
    color: '#fff',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },

  content: {
    padding: 20,
  },
  name: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 600,
  },
  price: {
    fontFamily: 'Marcellus',
    fontSize: '38px',
    lineHeight: '46px',
    color: theme.palette.grey[50],
  },
  unit: {
    fontFamily: 'Kumbh Sans',
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.palette.grey[200],
  },

  description: {
    color: theme.palette.grey[50],
    marginTop: 4,
  },

  headerDisabled: {
    backgroundColor: theme.palette.primary[200],
    '& $name': {
      color: theme.palette.grey[900],
    },

    '& $price': {
      color: theme.palette.grey[700],
    },

    '& $description': {
      color: theme.palette.grey[700],
    },

    '& $unit': {
      color: theme.palette.grey[500],
    },
  },
  button: {
    backgroundColor: '#ffffff',
    borderColor: theme.palette.grey[300],
    color: theme.palette.primary.main,
    borderRadius: '8px',
    padding: '10px 20px',
    cursor: 'pointer',
    height: '40px',
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: theme.palette.primary[50],
    },
  },

  buttonDisabled: {
    backgroundColor: 'transparent',
    color: theme.palette.accent.red,
    borderColor: theme.palette.grey[400],
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: 'transparent', // No hover effect when disabled
    },
  },
  switchContainer: {
    display: 'flex',
    gap: 8,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    color: theme.palette.grey[600],
    marginTop: -8,
  },
  features: {
    width: '100%',
    color: '#1a1a1a',
  },
  switchIcon: {
    width: 24,
    height: 24,
    background: theme.palette.grey[400],
    borderRadius: 100,
  },
  switchIconChecked: {
    background: '#fff',
    color: theme.palette.primary.main,
  },
  featuresItem: {
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    display: 'flex',
    gap: 8,
    padding: '8px 0px',

    fontFamily: 'Kumbh Sans',
  },
  featuresItemText: {
    fontSize: 16,
    color: theme.palette.grey[900],
    lineHeight: '24px',
  },
}));

export default useStyles;
