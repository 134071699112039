import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import { filter, map } from 'lodash';

import AppPlusSelect from 'components/AppPlusSelect';
import Header from 'components/Header';
// import Refresh from 'components/Refresh';

import { AppRowCenterStart } from 'components/AppContainers';

// import { IconExport } from 'components/AppIcons';

// import exportInvoice from 'helpers/exportInvoice';

// import { useThunkDispatch } from 'hooks/generalHooks';
import { useRouter } from 'hooks/routerHooks';

import { userCanCreateInvoice } from 'selectors/rightsSelector/invoiceRequests';

// import { fetchInvoices } from 'actions/InvoiceActions';
// import { fetchRevenues } from 'actions/RevenueActions';

const InvoiceListHeader = (props) => {
  const { companySettings } = props;
  const canCreateInvoice = useSelector(userCanCreateInvoice);
  const { invoices: invoiceSettings } = companySettings;

  // const invoices = map(useSelector((state) => state.invoices));

  const { t } = useTranslation();
  const { history } = useRouter();

  // const dispatch = useThunkDispatch();

  // const fetchInvoicesCb = useCallback(() => dispatch(fetchInvoices()), [
  //   dispatch,
  // ]);

  // const fetchRevenuesCb = useCallback(() => dispatch(fetchRevenues()), [
  //   dispatch,
  // ]);

  const types = [
    {
      name: t('invoice.quotation'),
      show: invoiceSettings.quotation,
      onClick: () => history.push('/invoices/new', { type: 'quotation' }),
    },
    {
      name: t('invoice.order_form'),
      show: invoiceSettings.order_form,
      onClick: () => history.push('/invoices/new', { type: 'order_form' }),
    },
    {
      name: t('invoice.delivery_form'),
      show: invoiceSettings.delivery_form,
      onClick: () => history.push('/invoices/new', { type: 'delivery_form' }),
    },
    {
      name: t('invoice.invoice'),
      show: true,
      onClick: () => history.push('/invoices/new', { type: 'invoice' }),
    },
    {
      name: t('invoice.credit_note'),
      show: true,
      onClick: () => history.push('/invoices/new', { type: 'credit_note' }),
    },
    {
      name: t('revenues.revenue.create'),
      show: true,
      onClick: () => history.push('/revenue/new'),
    },
  ];

  // function downloadExport() {
  //   const element = document.createElement('a');
  //   const file = new Blob(
  //     [
  //       exportInvoice(
  //         filter(
  //           invoices,
  //           (invoice) =>
  //             (invoice.state === 'validated' || invoice.state === 'paid') &&
  //             (invoice.type === 'invoice' || invoice.type === 'credit_note')
  //         )
  //       ),
  //     ],
  //     {
  //       type: 'text/plain',
  //     }
  //   );
  //   element.href = URL.createObjectURL(file);
  //   element.download = 'factures.txt';
  //   document.body.appendChild(element); // Required for this to work in FireFox
  //   element.click();
  // }

  return (
    <Header name={t(`invoices.card_title`)} spaceBetween>
      <AppRowCenterStart>
        {/* <Refresh
          loadData={fetchInvoicesCb}
          loading={refreshing}
          callback={toggleRefresh}
          preFn={toggleRefresh}
          disabled={!canReadInvoice}
        />

        <IconExport onClick={downloadExport} /> */}
      </AppRowCenterStart>
      {canCreateInvoice && (
        <AppPlusSelect
          items={types.filter((type) => type.show)}
          name="name"
          label={t('create_income')}
        />
      )}
    </Header>
  );
};

InvoiceListHeader.propTypes = {
  companySettings: PropTypes.object.isRequired,
  refreshing: PropTypes.bool.isRequired,
  canReadInvoice: PropTypes.bool.isRequired,
  toggleRefresh: PropTypes.func.isRequired,
};

export default InvoiceListHeader;
