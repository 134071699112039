// VATRates
export const FETCH_VATRATES = 'FETCH_VATRATES';
export const FETCH_VATRATES_FOR_NO_VAT = 'FETCH_VATRATES_FOR_NO_VAT';
export const FULL_VATRATES = 'FULL_VATRATES';
export const FETCH_VATRATES_FAILED = 'FETCH_VATRATES_FAILED';

// Internal Activities
export const FETCH_ACTIVITIES = 'FETCH_ACTIVITIES';
export const FETCH_ACTIVITIES_FAILED = 'FETCH_ACTIVITIES_FAILED';
export const ADD_ACTIVITY = 'ADD_ACTIVITY';
export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';

// Categories : TODO rename with expense categories
export const FETCHING_CATEGORIES = 'FETCHING_CATEGORIES';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORIES_FAILED = 'FETCH_CATEGORIES_FAILED';
export const UPDATING_CATEGORY = 'UPDATING_CATEGORY';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILED = 'UPDATE_CATEGORY_FAILED';

// Revenue Categories
export const FETCHING_REVENUE_CATEGORIES = 'FETCHING_REVENUE_CATEGORIES';
export const FETCH_REVENUE_CATEGORIES_FAILED =
  'FETCH_REVENUE_CATEGORIES_FAILED';
export const FETCH_REVENUE_CATEGORIES_SUCCESS =
  'FETCH_REVENUE_CATEGORIES_SUCCESS';

// Revenues
export const FETCHING_REVENUES = 'FETCHING_REVENUES';
export const FETCH_REVENUES_FAILED = 'FETCH_REVENUES_FAILED';
export const FETCH_REVENUES_SUCCESS = 'FETCH_REVENUES_SUCCESS';
export const REFRESH_REVENUES_PAGINATE = 'REFRESH_REVENUES_PAGINATE';
export const FETCH_REVENUES_PAGINATE = 'FETCH_REVENUES_PAGINATE';

export const FETCHING_REVENUE = 'FETCHING_REVENUES';
export const FETCH_REVENUE_FAILED = 'FETCH_REVENUES_FAILED';
export const FETCH_REVENUE_SUCCESS = 'FETCH_REVENUES_SUCCESS';

export const CREATING_REVENUE = 'CREATING_REVENUE';
export const CREATE_REVENUE_SUCCESS = 'CREATE_REVENUE_SUCCESS';
export const CREATE_REVENUE_FAILED = 'CREATE_REVENUE_FAILED';

export const CLEAR_REVENUE = 'CLEAR_REVENUE';

export const FETCHING_REVENUE_OVERVIEW = 'FETCHING_REVENUE_OVERVIEW';
export const FETCH_REVENUE_OVERVIEW_SUCCESS = 'FETCH_REVENUE_OVERVIEW_SUCCESS';
export const FETCH_REVENUE_OVERVIEW_FAILED = 'FETCH_REVENUE_OVERVIEW_FAILED';

// Expenses
export const FETCH_EXPENSES = 'FETCH_EXPENSES';
export const FETCH_EXPENSE = 'FETCH_EXPENSE';
export const FETCH_EXPENSES_FAILED = 'FETCH_EXPENSES_FAILED';
export const CREATE_EXPENSE = 'CREATE_EXPENSE';
export const UPDATE_EXPENSE = 'UPDATE_EXPENSE';
export const DELETE_EXPENSE = 'DELETE_EXPENSE';
export const PENDING_EXPENSE = 'PENDING_EXPENSE';
export const VALIDATE_EXPENSE = 'VALIDATE_EXPENSE';
export const CANCEL_EXPENSE = 'CANCEL_EXPENSE';
export const PAY_EXPENSE = 'PAY_EXPENSE';
export const EXPENSE_VAT_LIST = 'EXPENSE_VAT_LIST';
export const EXPENSE_OVERVIEW = 'EXPENSE_OVERVIEW';
export const GET_EXPENSE_BY_CATEGORIES = 'GET_EXPENSE_BY_CATEGORIES';
export const GET_EXPENSE_BY_CATEGORIES_SUCCESS =
  'GET_EXPENSE_BY_CATEGORIES_SUCCESS';
export const GET_EXPENSE_BY_CATEGORIES_FAIL = 'GET_EXPENSE_BY_CATEGORIES_FAIL';
export const REFRESH_EXPENSES_PAGINATE = 'REFRESH_EXPENSES_PAGINATE';
export const FETCH_EXPENSES_PAGINATE = 'FETCH_EXPENSES_PAGINATE';
export const CLEAR_EXPENSE = 'CLEAR_EXPENSE';
export const ADD_TRANSFERS_TO_EXPENSE = 'ADD_TRANSFERS_TO_EXPENSE';
export const ADD_TRANSFERS_TO_EXPENSE_PAGINATE =
  'ADD_TRANSFERS_TO_EXPENSE_PAGINATE';

// Distance Rates
export const FETCH_DISTANCES_RATES = 'FETCH_DISTANCES_RATES';
export const FETCH_DISTANCES_RATES_FAILED = 'FETCH_DISTANCES_RATES_FAILED';

// Client
export const FETCHING_CLIENTS = 'FETCHING_CLIENTS';
export const FETCH_CLIENTS = 'FETCH_CLIENTS';
export const FETCH_CLIENTS_FAILED = 'FETCH_CLIENTS_FAILED';
export const CREATE_CLIENT = 'CREATE_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const UPDATE_CLIENT_BENEFICIARY = 'UPDATE_CLIENT_BENEFICIARY';

// Client Projects
export const FETCHING_CLIENT_PROJECTS = 'FETCHING_CLIENT_PROJECTS';
export const FETCH_CLIENT_PROJECTS = 'FETCH_CLIENT_PROJECTS';
export const FETCH_CLIENT_PROJECTS_FAILED = 'FETCH_CLIENT_PROJECTS_FAILED';
export const CREATE_CLIENT_PROJECT = 'CREATE_CLIENT_PROJECT';
export const UPDATE_CLIENT_PROJECT = 'UPDATE_CLIENT_PROJECT';
export const DELETE_CLIENT_PROJECT = 'DELETE_CLIENT_PROJECT';

// Company Users and roles
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_FAILED = 'FETCH_USERS_FAILED';

// User
export const FETCH_LOGGED_USER = 'FETCH_LOGGED_USER';
export const UPDATE_LOGGED_USER = 'UPDATE_LOGGED_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const CHANGE_AVATAR = 'CHANGE_AVATAR';
export const CHANGE_PASSPORT = 'CHANGE_PASSPORT';
export const CHANGE_NEW_IDENTITY = 'CHANGE_NEW_IDENTITY';
export const UPDATE_USER_BENEFICIARY = 'UPDATE_USER_BENEFICIARY';

// Provider
export const FETCH_PROVIDERS = 'FETCH_PROVIDERS';
export const CREATE_PROVIDER = 'CREATE_PROVIDER';
export const UPDATE_PROVIDER = 'UPDATE_PROVIDER';
export const UPDATE_PROVIDER_BENEFICIARY = 'UPDATE_PROVIDER_BENEFICIARY';
export const CREATE_MULTIPLE_PROVIDER = 'CREATE_MULTIPLE_PROVIDER';

// User Company
export const LOGGED_USER_COMPANY_SELECTED = 'LOGGED_USER_COMPANY_SELECTED';
export const LOGGED_USER_COMPANY_LOADED = 'LOGGED_USER_COMPANY_LOADED';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const SELECT_COMPANY = 'SELECT_COMPANY';
export const UPDATE_BANK_DETAILS_COMPANY = 'UPDATE_BANK_DETAILS';

// Company users
export const FETCH_COMPANY_USERS = 'FETCH_COMPANY_USERS';

// UI Language
export const LANGUAGE_CHANGED = 'LANGUAGE_CHANGED';
export const LANGUAGE_UNCHANGED = 'LANGUAGE_UNCHANGED';

// Roles
export const FETCH_ROLES = 'FETCH_ROLES';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAILED = 'FETCH_ROLES_FAILED';
export const CREATE_ROLE = 'CREATE_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';

// UserRoles
export const FETCH_USER_ROLES = 'FETCH_USER_ROLES';
export const FETCH_USER_ROLES_SUCCESS = 'FETCH_USER_ROLES_SUCCESS';
export const FETCH_USER_ROLES_FAILED = 'FETCH_USER_ROLES_SUCCESS';
export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE';

// InfoGreffe
export const FETCH_INFO_GREFFE = 'FETCH_INFO_GREFFE';
export const FETCH_INFO_GREFFE_ERROR = 'FETCH_INFO_GREFFE_ERROR';

// Items
export const FETCH_ITEMS = 'FETCH_ITEMS';
export const FETCH_ITEMS_FAILED = 'FETCH_ITEMS_FAILED';
export const CREATE_ITEM = 'CREATE_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';

// Invoices
export const FETCH_INVOICES = 'FETCH_INVOICES';
export const FETCH_INVOICES_PAGINATE = 'FETCH_INVOICES_PAGINATE';
export const FETCH_INVOICES_FAILED = 'FETCH_INVOICES_FAILED';
export const FETCH_INVOICE = 'FETCH_INVOICE';
export const CREATE_INVOICE = 'CREATE_INVOICE';
export const UPDATE_INVOICE = 'UPDATE_INVOICE';
export const VALIDATE_INVOICE = 'VALIDATE_INVOICE';
export const REFUND_INVOICE = 'REFUND_INVOICE';
export const INVOICE_TURNOVER = 'INVOICE_TURNOVER';
export const RESEND_INVOICE = 'RESEND_INVOICE';
export const REFRESH_INVOICES_PAGINATE = 'REFRESH_INVOICES_PAGINATE';

// Templates
export const FETCH_TEMPLATES = 'FETCH_TEMPLATES';
export const FETCH_TEMPLATES_FAILED = 'FETCH_TEMPLATES_FAILED';
export const CREATE_TEMPLATE = 'CREATE_TEMPLATE';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';

// Email templates
export const FETCH_EMAIL_TEMPLATES = 'FETCH_EMAIL_TEMPLATES';
export const FETCH_EMAIL_TEMPLATES_SUCCESS = 'FETCH_EMAIL_TEMPLATES_SUCCESS';
export const FETCH_EMAIL_TEMPLATES_FAILED = 'FETCH_EMAIL_TEMPLATES_FAILED';
export const CREATE_EMAIL_TEMPLATE = 'CREATE_EMAIL_TEMPLATE';
export const UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE';

// Bank API
export const CREATING_BANK_LINK = 'CREATING_BANK_LINK';
export const CREATE_BANK_LINK_FAILED = 'CREATE_BANK_LINK_FAILED ';
export const CREATE_BANK_LINK_SUCCESS = 'CREATE_BANK_LINK_SUCCESS ';
export const FETCHING_BANK_CATEGORIES = 'FETCHING_BANK_CATEGORIES';
export const FETCH_BANK_CATEGORIES_FAILED = 'FETCH_BANK_CATEGORIES_FAILED ';
export const FETCH_BANK_CATEGORIES_SUCCESS = 'FETCH_BANK_CATEGORIES_SUCCESS ';
export const FETCHING_BANK_LINK = 'FETCHING_BANK_LINK';
export const FETCH_BANK_LINK_FAILED = 'FETCH_BANK_LINK_FAILED';
export const FETCH_BANK_LINK_SUCCESS = 'FETCH_BANK_LINK_SUCCESS';
export const FETCHING_BANK_LINK_FUNNEL_URL = 'FETCHING_BANK_LINK_FUNNEL_URL';
export const FETCH_BANK_LINK_FUNNEL_URL_FAILED =
  'FETCH_BANK_LINK_FUNNEL_URL_FAILED';
export const FETCH_BANK_LINK_FUNNEL_URL_SUCCESS =
  'FETCH_BANK_LINK_FUNNEL_URL_SUCCESS';
export const FETCHING_BANK_ACCOUNTS = 'FETCHING_BANK_ACCOUNTS';
export const FETCH_BANK_ACCOUNT_FAILED = 'FETCH_BANK_ACCOUNT_FAILED';
export const FETCH_BANK_ACCOUNT_SUCCESS = 'FETCH_BANK_ACCOUNT_SUCCESS';
export const FETCHING_BALANCES = 'FETCHING_BALANCES';
export const FETCH_BALANCES_FAILED = 'FETCH_BALANCES_FAILED';
export const FETCH_BALANCES_SUCCESS = 'FETCH_BALANCES_SUCCESS';

// Book Keeping
export const FETCH_EXPORTS = 'FETCH_EXPORTS';

// Drawer
export const CHANGE_DRAWER_VISIBILITY = 'CHANGE_DRAWER_VISIBILITY';

// PDF preview / customer page
export const DISPLAY_PDF_PAGE = 'DISPLAY_PDF_PAGE';

// Aging Balance
export const GET_AGING_BALANCE = 'GET_AGING_BALANCE';
export const GET_AGING_BALANCE_SUCCESS = 'GET_AGING_BALANCE_SUCCESS';
export const GET_AGING_BALANCE_FAIL = 'GET_AGING_BALANCE_FAIL';
export const GET_AGING_BALANCE_OVERDUE = 'GET_AGING_BALANCE_OVERDUE';
export const GET_AGING_BALANCE_OVERDUE_SUCCESS =
  'GET_AGING_BALANCE_OVERDUE_SUCCESS';
export const GET_AGING_BALANCE_OVERDUE_FAIL = 'GET_AGING_BALANCE_OVERDUE_FAIL';

// Transaction
export const ADD_TRANSACTIONS = 'ADD_TRANSACTIONS';

export const APP_INITIALIZED = 'APP_INITIALIZED';
export const GLOBAL_ERROR = 'GLOBAL_ERROR';

// Wallet
export const FETCH_WALLET = 'FETCH_WALLET';
export const FETCH_WALLET_FAILED = 'FETCH_WALLET_FAILED';
export const FETCH_KYC_USER = 'FETCH_KYC_USER';
export const FETCH_KYC_USER_FAILED = 'FETCH_KYC_USER_FAILED';
export const FETCH_USER_TREEZOR = 'FETCH_USER_TREEZOR';
export const FETCH_USER_TREEZOR_FAILED = 'FETCH_USER_TREEZOR_FAILED';

// Virtual Card
export const FETCH_CARD = 'FETCH_CARD';
export const FETCH_CARD_FAILED = 'FETCH_CARD_FAILED';

export const CARD_CREATE_SUCCESS = 'CARD_CREATE_SUCCESS';
export const CREATING_CARD = 'CREATING_CARD';
export const CREATE_CARD_SUCCESS = 'CREATE_CARD_SUCCESS';
export const CREATE_CARD_FAILED = 'CREATE_CARD_FAILED';
export const PROCESSING_LOCK_UNLOCK_CARD = 'PROCESSING_LOCK_UNLOCK_CARD';
export const LOCK_UNLOCK_CARD_SUCCESS = 'LOCK_UNLOCK_CARD_SUCCESS';
export const LOCK_UNLOCK_CARD_FAILED = 'LOCK_UNLOCK_CARD_FAILED';
export const UPDATING_CARD_CONFIG = 'UPDATING_CARD_CONFIG';
export const UPDATE_CARD_CONFIG_SUCCESS = 'UPDATE_CARD_CONFIG_SUCCESS';
export const UPDATE_CARD_CONFIG_FAILED = 'UPDATE_CARD_CONFIG_FAILED';

// Physical Card
export const ACTIVATE_PHYSICAL_CARD_SUCCESS = 'ACTIVATE_PHYSICAL_CARD_SUCCESS';
export const FETCHING_DETAIL_CARD = 'FETCHING_DETAIL_CARD';
export const FETCH_DETAIL_CARD_SUCCESS = 'FETCH_DETAIL_CARD_SUCCESS';
export const FETCH_DETAIL_CARD_FAILED = 'FETCH_DETAIL_CARD_FAILED';

// Card transactions
export const FETCHING_CARD_TRANSACTIONS = 'FETCHING_CARD_TRANSACTIONS';
export const FETCH_CARD_TRANSACTIONS_SUCCESS =
  'FETCH_CARD_TRANSACTIONS_SUCCESS';
export const FETCH_CARD_TRANSACTIONS_FAILED = 'FETCH_CARD_TRANSACTIONS_FAILED';

// Person User Treezor
export const FETCH_PERSON_USER_TREEZOR = 'FETCH_PERSON_USER_TREEZOR';
export const FETCH_PERSON_USER_TREEZOR_FAILED =
  'FETCH_PERSON_USER_TREEZOR_FAILED';
export const CREATE_TREEZOR_USER_FAILED = 'CREATE_TREEZOR_USER_FAILED';
export const UPDATE_TREEZOR_DOC = 'UPDATE_TREEZOR_DOC';
export const PROCESSING_CANCEL_TREEZOR_USER = 'PROCESSING_CANCEL_TREEZOR_USER';
export const SUCCESS_CANCEL_TREEZOR_USER = 'SUCCESS_CANCEL_TREEZOR_USER';

// Treezor Balances
export const FETCHING_TREEZOR_BALANCES = 'FETCHING_TREEZOR_BALANCES';
export const FETCH_TREEZOR_BALANCES_SUCCESS = 'FETCH_TREEZOR_BALANCES_SUCCESS';
export const FETCH_TREEZOR_BALANCES_FAILED = 'FETCH_TREEZOR_BALANCES_FAILED';

// Wallet Transaction
export const FETCH_WALLET_TRANSACTIONS = 'FETCH_WALLET_TRANSACTIONS';
export const FETCH_WALLET_TRANSACTIONS_FAILED =
  'FETCH_WALLET_TRANSACTIONS_FAILED';
export const UPDATE_WALLET_TRANSACTION = 'UPDATE_WALLET_TRANSACTION';
export const UPDATE_WALLET_TRANSACTION_FAILED =
  'UPDATE_WALLET_TRANSACTION_FAILED';
export const FETCH_TRANSACTIONS_PAGINATE = 'FETCH_TRANSACTIONS_PAGINATE';
export const REFRESH_TRANSACTIONS_PAGINATE = 'REFRESH_TRANSACTIONS_PAGINATE';

// TEMPORARY data
export const TREEZOR_COMPANY_ID = 'TREEZOR_COMPANY_ID';
export const TREEZOR_USER_ID = 'TREEZOR_USER_ID';
export const TREEZOR_COMPANY_FORM = 'TREEZOR_COMPANY_FORM';
export const TREEZOR_USER_FORM = 'TREEZOR_USER_FORM';
export const TREEZOR_DOCUMENT_FORM = 'TREEZOR_DOCUMENT_FORM';
export const TREEZOR_CGU_FORM = 'TREEZOR_CGU_FORM';

export const ONBOARDING_TREEZOR = 'ONBOARDING_TREEZOR';
export const FETCHING_TREEZOR_USERS = 'FETCHING_TREEZOR_USERS';
export const FETCH_TREEZOR_USERS_SUCCESS = 'FETCH_TREEZOR_USERS_SUCCESS';
export const FETCH_TREEZOR_USERS_FAILED = 'FETCH_TREEZOR_USERS_FAILED';

// Payout
export const CREATING_PAYOUT = 'CREATING_PAYOUT';
export const CREATE_PAYOUT_SUCCESS = 'CREATE_PAYOUT_SUCCESS';
export const CREATE_PAYOUT_FAILED = 'CREATE_PAYOUT_FAILED';

// Check UI
export const SET_SHOW_CHECK_STATUS = 'SET_SHOW_CHECK_STATUS';

// Scann OCR
export const STORE_SCAN_OCR_VALUE = 'STORE_SCAN_OCR_VALUE';

// Resize screen
export const RESIZE_SCREEN = 'RESIZE_SCREEN';

export const FETCH_COMPANY_SUBSCRIPTION_START =
  'FETCH_COMPANY_SUBSCRIPTION_START';
export const FETCH_COMPANY_SUBSCRIPTION = 'FETCH_COMPANY_SUBSCRIPTION';

export const FETCH_COMPANY_SUBSCRIPTION_HISTORY =
  'FETCH_COMPANY_SUBSCRIPTION_HISTORY';

// App loading
export const APP_LOADING = 'APP_LOADING';

// App snackbar
export const APP_SNACKBAR = 'APP_SNACKBAR';
