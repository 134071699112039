import React, { useMemo, useState } from 'react';
import { styled, Switch, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import cl from 'classnames';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { INACTIVE } from 'constants/subscriptions';
import SvgIcon from 'components/AppIcons/SvgIcon';
import useStyles from './styles';
import BadgeCheck from '../../assets/icon/badge-check.svg';

const MaterialUISwitch = styled(Switch)(() => ({
  width: 52,
  height: 32,
  padding: 0,
  borderRadius: 100,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(4px)',
    top: 2.5,
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb': {
        backgroundColor: '#fff',
        '& .MuiSwitch-iconChecked': {
          display: 'block',
        },
        '& .MuiSwitch-iconUnchecked': {
          display: 'none',
        },
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#3F3399',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#9CA3AF',
    width: 26,
    height: 26,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiSwitch-iconChecked': {
      display: 'none',
    },
    '& .MuiSwitch-iconUnchecked': {
      display: 'block',
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#E5E7EB',
    borderRadius: 20 / 2,
  },
}));

const PricingColumn = ({ subscriptions, handleChoosePricing, monthly }) => {
  const [AI, setAI] = useState(false);

  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const curSubscription = useMemo(
    () => subscriptions?.[AI],
    [subscriptions, AI]
  );

  const getSubscriptionInfo = (name) => {
    return name.includes('Starter')
      ? {
          description: 'discovery_description',
          dataName: 'basique',
        }
      : {
          description: 'efficiency_descriptions',
          dataName: 'premium',
        };
  };
  const { description, dataName } = getSubscriptionInfo(curSubscription?.name);
  const featuresData = useMemo(
    () =>
      t(`onboarding.formula_descriptions.${dataName}`, {
        returnObjects: true,
      }),
    []
  );

  const isInactiveSubscription = useMemo(
    () => curSubscription?.status === INACTIVE,
    [curSubscription]
  );

  return (
    <div className={classes.priceColumn}>
      <div
        className={cl(classes.header, {
          [classes.headerDisabled]: isInactiveSubscription,
        })}
      >
        <div className={classes.name}>{curSubscription?.name}</div>
        <div className={classes.price_description}>
          <div className={classes.price}>
            {(curSubscription.amount / 100)
              .toFixed(2)
              .toString()
              .replace('.', ',')}
            €{' '}
            <span className={classes.unit}>
              {monthly
                ? t('stripe_pricing.unit_month')
                : t('stripe_pricing.unit_year')}
            </span>
          </div>
          <div className={classes.description}>
            {t(`onboarding.${description}`)}
          </div>
        </div>
        <button
          className={cl(classes.button, {
            [classes.buttonDisabled]: isInactiveSubscription,
          })}
          onClick={() => handleChoosePricing(curSubscription._id)}
          disabled={isInactiveSubscription}
        >
          {isInactiveSubscription
            ? t('onboarding.cant_upgrade')
            : t('onboarding.start_trial')}
        </button>
      </div>
      <div className={classes.content}>
        <div className={classes.switchContainer}>
          <div>{t('stripe_pricing.use_ai')}</div>
          <MaterialUISwitch
            checked={AI}
            onChange={() => setAI(!AI)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            icon={
              <CloseIcon
                className={cl('MuiSwitch-iconUnchecked', classes.switchIcon)}
              />
            }
            checkedIcon={
              <CheckIcon
                className={cl(
                  'MuiSwitch-iconChecked',
                  classes.switchIcon,
                  classes.switchIconChecked
                )}
              />
            }
          />
        </div>
        <div className={classes.features}>
          {featuresData.map((data, index) => {
            return (
              <div key={index} className={classes.featuresItem}>
                <SvgIcon
                  path={BadgeCheck}
                  color={theme.palette.primary.hover}
                  width={24}
                  height={24}
                />
                <span className={cl(classes.featuresItemText)}>{data}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

PricingColumn.propTypes = {
  subscriptions: PropTypes.object,
  handleChoosePricing: PropTypes.func.isRequired,
  monthly: PropTypes.bool,
};

export default PricingColumn;
