import { Providers as Rights } from '../../config/Rights';
import { hasRequestedAccess } from '../../helpers/RoleHelper';

export const userCanCreateProvider = () => {
  return hasRequestedAccess(Rights.ProvidersCreate);
};

export const userCanUpdateProvider = () => {
  return hasRequestedAccess(Rights.ProvidersUpdate);
};
