import { v4 as uuidv4 } from 'uuid';
import { EXPENSE_STATE } from 'constants/expense';
import { getFileExtension } from 'helpers/utils';
import mediaService from 'services/S3FileService';
import { authInstance as http } from '../../../helpers/axiosInterceptor';

export const getPayloadSavePurchase = (values) => {
  return {
    date: values.date,
    // company_id, user_id, => these values are added on the API
    category_id: values.category_id,
    sub_category_id: values.sub_category_id,
    client_id: values.client_id,
    project_id: values.project_id,
    invoice_nb: values.invoice_nb,
    internal_activity: values.internal_activity,
    provider: values.provider || null,
    state: values.state,
    due_date: values.due_date,
    filename: values.filename,
    payment_type: values.payment_type,
    currency: 'EUR',
    description: values.description,
    buyerOrderReferencedDocument: values.buyerOrderReferencedDocument,
    reference: values.reference,
    is_billable: values.is_billable,
    is_intern: values.is_intern,
    travel: {
      from: values.travel_from,
      to: values.travel_to,
    },
    // belongsToGroup: values.belongsToGroup, default value will be returned
    amounts: values.amounts,
    taxBasisTotalAmount: values.taxBasisTotalAmount,
    taxTotalAmount: values.taxTotalAmount,
    grandTotalAmount: values.grandTotalAmount,
    duePayableAmount: values.duePayableAmount,
    billNumber: values.billNumber,
    orderNumber: values.orderNumber,
    action: values.action,
  };
};

export const uploadFile = async (values, { setFieldValue }, company) => {
  let filename = null;
  if (values.filename && values.filename instanceof File) {
    try {
      const file = values.filename;
      const ext = getFileExtension(values.filename.name);
      const folder = `company-files-${company.alternativeId}`;
      const fileName = `${uuidv4()}-${new Date().toISOString()}.${ext}`;

      filename = `${folder}/${fileName}`;

      await mediaService.upload(file, folder, fileName);
    } catch (e) {
      setFieldValue('uploadError', true);
      setFieldValue('action', null);
      setFieldValue('state', 'draft');
      throw new Error({ message: 'error upload file' });
    }
  } else {
    filename = values.filename;
  }
  return filename;
};

export const handleValidatePurchase = async (
  values,
  { setFieldValue },
  company
) => {
  const filename = await uploadFile(values, { setFieldValue }, company);
  const payload = getPayloadSavePurchase({
    ...values,
    filename,
    state: EXPENSE_STATE.VALIDATED,
  });

  console.log('API data', payload);
  return http.put(
    `${process.env.REACT_APP_EXPENSES_URL}/purchases/${values.id}`,
    payload
  );
};

export const handleCreatePurchase = async (
  values,
  { setFieldValue },
  company
) => {
  const filename = await uploadFile(values, { setFieldValue }, company);
  const payload = getPayloadSavePurchase({
    ...values,
    filename,
    state: EXPENSE_STATE.DRAFT,
  });
  const { data } = await http.post(
    `${process.env.REACT_APP_EXPENSES_URL}/purchases`,
    payload
  );

  return data;
};

export default {};
