import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ConfirmDialog from 'components/ConfirmDialog';
import useImportAction from '../hooks/useImportActions';
import { useRouter } from '../../../hooks/routerHooks';
import Header from '../../../components/Header';
import Layout from '../../../components/Layout';
import ItemImportBody from '../Table/ItemImportBody';
import useImportItem from '../hooks/useImportItem';

const ImportListItem = () => {
  const { history } = useRouter();
  const { t } = useTranslation();
  const { onFileChange, Items, checkValidateData } = useImportItem();
  const {
    handleDeleteRow,
    handleOnConfirm,
    handleToggleDialog,
    handleUpdateClient,
    handleSave,
    state: {
      confirmMessage,
      isShowDialog,
      confirmType,
      isCancelConfirm,
      showSpinner,
    },
  } = useImportAction(Items.ItemList, Items.setItemList, checkValidateData);

  return (
    <Fragment>
      <ConfirmDialog
        toggleDialog={handleToggleDialog}
        confirmMessage={confirmMessage}
        isShowDialog={isShowDialog}
        type={confirmType}
        onConfirm={handleOnConfirm}
        isCancelConfirm={isCancelConfirm}
      />
      <Layout
        header={
          <Header
            name={t('settings_section.item.title')}
            spaceBetween
            goBack={() => {
              history.goBack();
            }}
          />
        }
        sidebarLeft={true}
        sidebarRight={null}
        showUserCard={true}
        body={
          <ItemImportBody
            onFileChange={onFileChange}
            Items={Items}
            handleDeleteRow={handleDeleteRow}
            handleUpdateClient={handleUpdateClient}
            checkValidateData={checkValidateData}
            handleSave={handleSave}
            showSpinner={showSpinner}
          />
        }
      />
    </Fragment>
  );
};

ImportListItem.propTypes = {
  openProjectFormDialog: PropTypes.func,
  clientId: PropTypes.string,
};

export default ImportListItem;
