/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import styles from './styles.module.css';

const DndExample = ({
  pdfImages,
  setPdfImages,
  selectedIndices,
  selectedPages,
  onSelectPage,
  setSelectedIndices,
}) => {
  const getIndices = (indexSource, indexDes) => {
    const isSelectedSource = selectedIndices.includes(indexSource);
    const isSelectedDes = selectedIndices.includes(indexDes);
    if (
      (isSelectedDes && isSelectedSource) ||
      (!isSelectedDes && !isSelectedSource)
    )
      return selectedIndices;

    if (isSelectedSource) {
      const filteredIndices = selectedIndices.filter(
        (item) => item !== indexSource
      );
      return [...filteredIndices, indexDes];
    }

    const filteredIndices = selectedIndices.filter((item) => item !== indexDes);
    return [...filteredIndices, indexSource];
  };

  const onMoveItems = (sourceIndex, desIndex) => {
    const newItems = Array.from(pdfImages);
    const [movedItem] = newItems.splice(sourceIndex, 1);
    newItems.splice(desIndex, 0, movedItem);

    const newIndices = getIndices(sourceIndex + 1, desIndex + 1);
    setSelectedIndices(newIndices);
    setPdfImages(newItems);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    onMoveItems(source.index, destination.index);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              gap: '20px 100px',
              maxHeight: 400,
              overflow: 'auto',
              border: '1px solid #dddddd',
              borderRadius: 16,
              padding: pdfImages && pdfImages.length > 0 ? '20px 0' : 0,
            }}
          >
            {pdfImages.map((item, index) => {
              const indice = index + 1;
              const disable =
                selectedPages && selectedPages.find((item) => item === indice);
              return (
                <Draggable key={item} draggableId={item} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                        cursor: 'pointer',
                      }}
                      className={
                        selectedIndices.find(
                          (selected) => selected === indice
                        ) === indice
                          ? styles['split-item-active']
                          : styles['split-item']
                      }
                    >
                      <img
                        style={{
                          width: '100%',
                          height: '100%',
                          ...(disable
                            ? {
                                objectFit: 'cover',
                                opacity: '0.4',
                              }
                            : {}),
                        }}
                        onClick={() => {
                          // if (!disable) {
                          //   onSelectPage(index);
                          // }
                          onSelectPage(index);
                        }}
                        src={item}
                        alt="preview"
                      ></img>
                      <div className={styles.indiceAction}>
                        <KeyboardArrowLeftIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            if (index > 0) onMoveItems(index, index - 1);
                          }}
                        />
                        {indice}
                        <KeyboardArrowRightIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            if (index < pdfImages.length)
                              onMoveItems(index, index + 1);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DndExample;
