import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { createItem, fetchItems } from 'actions/ItemsActions';
import snackbarService from 'services/SnackbarService';
import { Item } from './useImportItem';

const useImportActions = (itemList, setItemList, checkValidateData) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const CONFIRM_TYPE = useState(null);
  const ROW_SELECTED = useState(null);
  const IS_CANCEL_CONFIRM = useState(true);
  const IS_SHOW_DIALOG = useState(false);
  const CONFIRM_MESSAGE = useState('');
  const [showSpinner, setShowSpinner] = useState(false);

  const { t } = useTranslation();

  const [confirmType, setConfirmType] = CONFIRM_TYPE;
  const [rowSelected, setRowSelected] = ROW_SELECTED;
  const [isCancelConfirm, setIsCancelConfirm] = IS_CANCEL_CONFIRM;
  const [isShowDialog, setIsShowDialog] = IS_SHOW_DIALOG;
  const [confirmMessage, setConfirmMessage] = CONFIRM_MESSAGE;

  const handleRemoveProvider = () => {
    const newItem = itemList.filter(({ index }) => index !== rowSelected);
    setItemList(newItem);
    setRowSelected(null);
    setConfirmType(null);
  };

  const handleDeleteRow = (index) => {
    setConfirmType('deleteRow');
    setConfirmMessage(t('settings_section.item.delete_item'));
    setIsShowDialog(true);
    setRowSelected(index);
    setIsCancelConfirm(true);
  };

  const handleToggleDialog = React.useCallback(
    (bool) => {
      setIsShowDialog(bool);
    },
    [setIsShowDialog]
  );

  const handleUpdateClient = (item, id) => {
    const itemListClone = [...itemList];
    itemListClone[id] = item;
    checkValidateData(itemListClone, true);
  };

  const handleSave = () => {
    const selectedItems = itemList.filter((item) => item.isSelected);
    if (!isEmpty(selectedItems)) {
      setConfirmType('save');
      setConfirmMessage(t('settings_section.item.create_items'));
      setIsShowDialog(true);
      setIsCancelConfirm(true);
    } else {
      setConfirmType('selectedEmpty');
      setConfirmMessage(`0 ${t('settings_section.item.selected_items')}`);
      setIsShowDialog(true);
      setIsCancelConfirm(false);
    }
  };

  const getItemSelected = React.useCallback(() => {
    const newItemList = itemList.filter(({ isSelected }) => isSelected);

    return newItemList;
  }, [itemList]);

  const navigate = (path) => {
    history.push(path);
  };

  const handleSubmit = React.useCallback(() => {
    const dataSubmit = getItemSelected().map((elem) => Item.toJson(elem));

    setShowSpinner(true);
    dispatch(
      createItem(dataSubmit, (err, res) => {
        setShowSpinner(false);
        if (err) {
          if (err.message === 'Duplicate item exists') {
            snackbarService.startSnackbar({
              message: t('settings_section.item.duplicate'),
              snackBarType: 'error',
              autoHideDuration: 6000,
            });
          } else
            snackbarService.startSnackbar({
              message: t('settings_section.item.create_failed'),
              snackBarType: 'error',
              autoHideDuration: 6000,
            });
        } else {
          const successMessage = [
            res.length,
            t('settings_section.item.import_successfully'),
          ].join(' ');

          snackbarService.startSnackbar({
            message: successMessage,
            snackBarType: 'success',
            autoHideDuration: 6000,
          });
          navigate('/settings/items');
          dispatch(fetchItems());
        }
      })
    );
    setConfirmType(null);
  }, [getItemSelected]);

  const handleOnConfirm = (isConfirm, type) => {
    if (isConfirm) {
      switch (type) {
        case 'deleteRow':
          handleRemoveProvider();
          break;
        case 'save':
          handleSubmit();
          break;
        case 'importError':
          setConfirmType(null);
          setIsShowDialog(false);
          setIsCancelConfirm(true);
          break;
        case 'importEmpty':
          setConfirmType(null);
          setIsShowDialog(false);
          setIsCancelConfirm(true);
          break;
        case 'selectedEmpty':
          setConfirmType(null);
          setIsShowDialog(false);
          setIsCancelConfirm(true);
          break;
        default:
          break;
      }
    }
  };

  return {
    state: {
      confirmType,
      rowSelected,
      isCancelConfirm,
      isShowDialog,
      confirmMessage,
      showSpinner,
    },
    handleDeleteRow,
    handleOnConfirm,
    handleToggleDialog,
    handleUpdateClient,
    handleSubmit,
    handleSave,
  };
};

export default useImportActions;
