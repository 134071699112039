import { APP_SNACKBAR } from 'actions/Types';
import { globalDispatch } from '../../store';

const showSnackbar = (config) => {
  globalDispatch({
    type: APP_SNACKBAR,
    payload: config,
  });
};

const startSnackbar = (config) => {
  const {
    message = '',
    vertical = 'top',
    horizontal = 'center',
    autoHideDuration = 5000,
    snackBarType = 'error',
  } = config;
  showSnackbar({
    open: true,
    config: {
      message,
      vertical,
      horizontal,
      autoHideDuration,
      snackBarType,
    },
  });
};

const endSnackbar = () => {
  showSnackbar({ open: false });
};

const snackbarService = { startSnackbar, endSnackbar };
export default snackbarService;
