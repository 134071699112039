import AppSubscriptions from 'components/AppSubscriptions';
import { useParams, useHistory } from 'react-router-dom';
import React from 'react';
import SignUpService from 'services/SignUpService';
import spinnerService from 'services/SpinnerService';

const SelectSubscription = () => {
  const history = useHistory();
  const { companyId } = useParams();
  const navigate = (path) => {
    history.push(path);
  };

  const handleChoosePricing = async (subscriptionId) => {
    try {
      if (subscriptionId) {
        spinnerService.startSpinner();
        await SignUpService.connectSubscriptionRemoveApiVersion(
          subscriptionId,
          companyId
        );
        navigate('/home');
      }
    } catch (error) {
      console.error(error);
    } finally {
      spinnerService.resetSpinner();
    }
  };

  return (
    <div>
      <AppSubscriptions handleChoosePricing={handleChoosePricing} />
    </div>
  );
};

export default SelectSubscription;
