import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import spinnerService from 'services/SpinnerService';
import { DEFAULT_CATEGORY_ID } from 'config/Constants';
import { fetchProviders } from 'actions/ProviderAction';
import CustomGenericDialog from '../../../components/CustomGenericDialog';
import Layout from '../../../components/Layout';
import Form from '../Form';
import ProviderListHeader from '../components/ProvidersListHeader';
import ProviderListBody from '../Table/ProvidersListBody';
import { userCanUpdateProvider } from '../../../selectors/rightsSelector/providerRequests';

const UserFormDialog = ({
  showDialog,
  selectedProviderId,
  closeProviderFormDialog,
}) => {
  const { t } = useTranslation();
  const canUpdateProvider = useSelector(userCanUpdateProvider);

  const states = useSelector((state) => state);
  const { tree } = states.categories;
  const filteredTree = !tree
    ? []
    : [
        ...tree
          .find((category) => category._id === DEFAULT_CATEGORY_ID)
          .children.filter(
            (category) => !category.travel.isTravelWithDistanceRate
          ),
        ...tree.filter((category) => category.order > 0),
      ].map((item) => ({
        display: t(`expenses.categories.${item.display}`),
        children: item.children,
        _id: item._id,
      }));

  return (
    showDialog && (
      <CustomGenericDialog
        title={
          selectedProviderId
            ? t('settings_section.providers.edit_title')
            : t('settings_section.providers.create_title')
        }
        closeDialog={closeProviderFormDialog}
        onConfirmType="submit"
        onConfirmText="submit"
        iconClose
        color="secondary"
        //   setHeightManualy="650px"
      >
        <Form
          id={selectedProviderId}
          closeDialog={closeProviderFormDialog}
          tree={filteredTree}
          activities={states.activities}
          canUpdateProvider={canUpdateProvider}
        />
      </CustomGenericDialog>
    )
  );
};

const ProviderList = () => {
  const dispatch = useDispatch();

  const [selectedProvider, setSelectedProvider] = useState(null);
  const [showFormDialog, setShowFormDialog] = useState(false);

  useEffect(() => {
    spinnerService.startSpinner();
    dispatch(fetchProviders()).finally(() => spinnerService.endSpinner());
  }, []);

  function openProviderFormDialog(providerId = null) {
    setShowFormDialog(true);
    setSelectedProvider(providerId);
  }

  function closeProviderFormDialog() {
    setShowFormDialog(false);
    setSelectedProvider(null);
  }

  function renderBody() {
    return (
      <Fragment>
        <UserFormDialog
          showDialog={showFormDialog}
          selectedProviderId={selectedProvider}
          closeProviderFormDialog={closeProviderFormDialog}
        />

        <ProviderListBody openProviderFormDialog={openProviderFormDialog} />
      </Fragment>
    );
  }

  return (
    <Layout
      header={
        <ProviderListHeader openProviderFormDialog={openProviderFormDialog} />
      }
      showUserCard
      body={renderBody()}
    />
  );
};

UserFormDialog.propTypes = {
  showDialog: PropTypes.bool,
  selectedProviderId: PropTypes.string,
  closeProviderFormDialog: PropTypes.func,
};

ProviderListBody.propTypes = {
  openProviderFormDialog: PropTypes.func,
};

export default ProviderList;
